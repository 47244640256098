import React from 'react';
import './CategoryHeader.css';

function CategoryHeader() {
  return (
    <header className="App-header">
      <div className="line line1"></div>
      <p>Our Top Categories</p>
      <div className=" line line2"></div>
    </header>
  );
}

export default CategoryHeader;
