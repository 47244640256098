import React from "react";
import "./Contact.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Helmet } from "react-helmet";
import SolutionStep from "../About/SolutionStep";

function Contact() {
  return (
    <div className="CP-about-section" id="about">
      <Helmet>
        <title>Kamali Crackers - Home</title>
        {/* Meta Description */}
        <meta
          name="description"
          content="Welcome to Kamali Crackers - your ultimate destination for buying premium quality crackers and fireworks online in Tamil Nadu. Explore our wide range of products and shop securely with fast delivery options."
        />

        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="Kamali crackers, buy crackers online Sivakasi, online crackers Sivakasi, Sivakasi fireworks, Diwali crackers online Sivakasi, wholesale crackers Sivakasi, Sivakasi firecrackers, order crackers online Sivakasi, best crackers Sivakasi, cheap crackers Sivakasi, crackers delivery Sivakasi, Kamali crackers shop online, festival fireworks Sivakasi, Kamali crackers wholesale, crackers online Tamil Nadu, fireworks online Sivakasi, Kamali crackers for Diwali, bulk crackers Sivakasi, discount crackers Sivakasi, crackers wholesale Sivakasi, Sivakasi fireworks for festivals, buy fireworks online Sivakasi, Kamali crackers sale, Sivakasi gift box crackers, Sivakasi fancy crackers"
        />

        {/* Author */}
        <meta name="author" content="Sankarapandi, Kamali Crackers" />

        {/* Open Graph Meta Tags for Social Media */}
        <meta property="og:title" content="Kamali Crackers - Home" />
        <meta
          property="og:description"
          content="Welcome to Kamali Crackers - your ultimate destination for buying premium quality crackers and fireworks online in Tamil Nadu. Explore our wide range of products and shop securely with fast delivery options."
        />
        <meta property="og:image" content="/brand-image.jpg" />
        <meta property="og:url" content="https://www.kamalicrackers.com" />
        <meta property="og:type" content="website" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Kamali Crackers - Home" />
        <meta
          name="twitter:description"
          content="Welcome to Kamali Crackers - your ultimate destination for buying premium quality crackers and fireworks online in Tamil Nadu. Explore our wide range of products and shop securely with fast delivery options."
        />
        <meta name="twitter:image" content="/brand-image.jpg" />
      </Helmet>

      <div className="CP-about-image-content">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d37443.73673425614!2d77.91485138818764!3d9.439982063626516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b06cdb91ef3a56b%3A0x80ae39f96fe2947b!2sKALEESWARI%20CRACKERS!5e0!3m2!1sen!2sin!4v1719945715303!5m2!1sen!2sin"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          className="AP-about-map"
          title="Kamali Crackers Location"
        ></iframe>
      </div>
      <div className="CP-about-text-content">
        <h3 className="CP-about-title">
          <span>Contact Us</span>
        </h3>
        <p className="CP-about-description">
          Get in Touch with Us, We are here to help you with any queries or
          assistance you need. Reach out to us through any of the following
          methods:
        </p>

        <SolutionStep
          title="📞 Phone:"
          description="9005900858"
        />

        <SolutionStep
          title="🏠 Address:"
          description="Kamali Crackers, 1/740, RR nagar, Viudhunagar, Tamilnadu, India - 626204"
        />
        <SolutionStep
          title="🌎 Website:"
          description="www.kamalicrackers.com For more information about our products and services, feel free to visit our website or call us. We look forward to serving you and ensuring your celebrations are as bright and joyful as possible!."
        />
      </div>
      <div className="MV-CP-about-image-content">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d37443.73673425614!2d77.91485138818764!3d9.439982063626516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b06cdb91ef3a56b%3A0x80ae39f96fe2947b!2sKALEESWARI%20CRACKERS!5e0!3m2!1sen!2sin!4v1719945715303!5m2!1sen!2sin"
          width="300"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          className="AP-about-map"
          title="Kamali Crackers Location"
        ></iframe>
      </div>
    </div>
  );
}

export default Contact;
