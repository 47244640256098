import React, { useState, useEffect, useRef } from "react";
import "./Pos.css";
import { useNavigate } from 'react-router-dom';

const BillDetails = ({ tabId, handleSaveOrder, calculateBillDetails, customerDetailsRef, amountReceivedRef }) => {
  const [customerDetails, setCustomerDetails] = useState({
    name: "",
    phone: "+91 ",
  });
  const [errors, setErrors] = useState({ name: "", phone: "" });

  const nameInputRef = useRef(null);
  const phoneInputRef = useRef(null);
  const amountReceivedInputRef = useRef(null);

  const navigate = useNavigate();

  const handlePartialPay = () => {
    navigate('/ijwyshs_admin/partial-pay'); 
  };

  const handleBillHistory = () => {
    navigate('/ijwyshs_admin/bill-history'); 
  };

  useEffect(() => {
    if (customerDetailsRef) {
      customerDetailsRef.current = nameInputRef.current;
    }
    if (amountReceivedRef) {
      amountReceivedRef.current = amountReceivedInputRef.current;
    }
  }, [customerDetailsRef, amountReceivedRef]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      if (value.startsWith("+91 ")) {
        setCustomerDetails({ ...customerDetails, phone: value });
      } else if (value.startsWith("+91")) {
        setCustomerDetails({ ...customerDetails, phone: value + " " });
      } else {
        setCustomerDetails({ ...customerDetails, phone: "+91 " + value });
      }
      const trimmedValue = value.replace(/\s+/g, "").replace(/-/g, "");
      const phoneWithoutCountryCode = trimmedValue.slice(3);
      if (trimmedValue === "+91" || trimmedValue === "+91XXXXX-XXXXX") {
        setErrors({ ...errors, phone: "" });
      } else if (!/^\d{10}$/.test(phoneWithoutCountryCode)) {
        setErrors({ ...errors, phone: "Phone number must be 10 digits" });
      } else {
        setErrors({ ...errors, phone: "" });
      }
    } else {
      setCustomerDetails({ ...customerDetails, [name]: value });
      if (value.trim() === "") {
        setErrors({
          ...errors,
          [name]: `${name.charAt(0).toUpperCase() + name.slice(1)} is required`,
        });
      } else {
        setErrors({ ...errors, [name]: "" });
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (event.target.name === "name") {
        phoneInputRef.current.focus();
      } else if (event.target.name === "phone") {
        amountReceivedInputRef.current.focus();
      } else if (event.target.name === "amountReceived") {
        document.getElementById("save").click();
      }
    } else if (event.key === "ArrowDown") {
      if (event.target.name === "name") {
        phoneInputRef.current.focus();
      } else if (event.target.name === "phone") {
        amountReceivedInputRef.current.focus();
      }
    } else if (event.key === "ArrowUp") {
      if (event.target.name === "phone") {
        nameInputRef.current.focus();
      } else if (event.target.name === "amountReceived") {
        phoneInputRef.current.focus();
      }
    }
  };

  useEffect(() => {
    const handleGlobalKeydown = (event) => {
      if (event.ctrlKey && event.key === "c") {
        document.getElementById("customer-details").focus();
      }
      if (event.ctrlKey && event.key === "b") {
        event.preventDefault();
        document.getElementById("history").click();
      }
      if (event.ctrlKey && event.key === "p") {
        event.preventDefault();
        document.getElementById("partialpay").click();
      } else if (event.ctrlKey && event.key === "a") {
        event.preventDefault();
        const elements = document.getElementsByClassName("amount-received");
        if (elements.length > 0) {
          elements[0].focus();
        }
      }
    };

    window.addEventListener("keydown", handleGlobalKeydown);
    return () => {
      window.removeEventListener("keydown", handleGlobalKeydown);
    };
  }, []);

  const handleSaveClick = () => {
    const phoneWithoutCountryCode = customerDetails.phone
      .replace(/\s+/g, "")
      .replace(/-/g, "")
      .slice(3);

    const name = customerDetails.name || "Walk-in customer";
    const phone = phoneWithoutCountryCode ? customerDetails.phone : "+91 XXXXX - XXXXX";

    setCustomerDetails({ name, phone });

    if (!name || (phone !== "+91 XXXXX - XXXXX" && phoneWithoutCountryCode.length !== 10)) {
      if (!name) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          name: "Name is required *",
        }));
      }
      if (phone !== "+91 XXXXX - XXXXX" && phoneWithoutCountryCode.length !== 10) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phone: "Phone number must be 10 digits *",
        }));
      }
      return;
    }
    handleSaveOrder(tabId);
  };

  const focusAmountReceived = () => {
    if (amountReceivedInputRef.current) {
      amountReceivedInputRef.current.focus();
      amountReceivedInputRef.current.readOnly = false; // Allow editing
    }
  };

  return (
    <>
      <div className="customer-details">
        <label>Customer Details</label>
        <input
          type="text"
          name="name"
          id="customer-details"
          placeholder="Name"
          value={customerDetails.name}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          ref={nameInputRef}
        />
        {errors.name && <div className="error-message">{errors.name}</div>}
        <input
          type="text"
          name="phone"
          placeholder="Phone"
          value={customerDetails.phone}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          ref={phoneInputRef}
        />
        {errors.phone && <div className="error-message">{errors.phone}</div>}
      </div>
      <div className="bill-details">
        <label>Bill Details</label>
        <div id={`sub-total-${tabId}`} className="sub-total">
          Sub Total: ₹0.00
        </div>
        <div id={`total-amount-${tabId}`} className="total-amount">
          Total Amount{" "}
          <span style={{ fontWeight: 100, fontSize: "14px" }}>
            (Items: 0, Quantity: 0):
          </span>{" "}
          ₹0.00
        </div>
      </div>
      <div className="cash-upi">
        <label>Cash/UPI</label>
        <div className="payment-method">
          <label>Cashier Name:</label>
          <select>
            <option>Admin</option>
            <option>Staff 1</option>
            <option>Staff 2</option>
            <option>Staff 3</option>
            <option>Staff 4</option>
          </select>
        </div>
        <div className="payment-method">
          <label>Payment Mode:</label>
          <select>
            <option>Cash</option>
            <option>UPI</option>
            <option>Cheque</option>
          </select>
        </div>
        <div className="discounts payment-method">
          <label>Discounts:</label>
          <input type="text" placeholder="₹0.00" readOnly />
        </div>
        <div className="payment-method">
          <label id="amount">Amount Received:</label>
          <input
            type="text"
            name="amountReceived"
            id={`amount-received-${tabId}`}
            className="amount-received"
            placeholder="₹0.00"
            onInput={() => calculateBillDetails(tabId)}
            onKeyDown={handleKeyDown}
            ref={amountReceivedInputRef}
          />
        </div>
        <div className="payment-method">
          <label>Balance:</label>
          <input
            type="text"
            id={`balance-${tabId}`}
            placeholder="₹0.00"
            readOnly
          />
        </div>
      </div>
      <div className="bottom-buttons">
        <button className="save-print" id="save" onClick={handleSaveClick}>
          Save
        </button>
        <div className="bottom-buttons1">
            <button className="partial-pay" id="partialpay" onClick={handlePartialPay}>
              Partial Pay
            </button>
            <button className="partial-pay" id="history" onClick={handleBillHistory}>
              Bill History
            </button>
        </div>
      </div>
    </>
  );
};

export default BillDetails;
