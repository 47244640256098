import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  InputAdornment,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const PartialPay = () => {
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(null);
  const [editRowId, setEditRowId] = useState(null);
  const [editAmountReceived, setEditAmountReceived] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  // ----------------------------keyboard shortcuts-----------------------------------

  useEffect(() => {
    // Handle keyboard shortcuts
    const handleKeydown = (event) => {
      if (event.ctrlKey && event.key === "p") {
        event.preventDefault();
        console.log("Custom Ctrl+p action");

        if (event.ctrlKey && event.key === "p") {
          document.getElementById("partialpay").click(); // Example: Focus on field with id 'partialpay'
        }
      }
    };

    window.addEventListener("keydown", handleKeydown);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeydown);
    };
  }, []);

  // ----------------------------------------------------------------------------------------------

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = () => {
    fetch(
      "https://kamalicrackers.com/ijwyshs_kamali_backend/get_partial_pay_orders.php"
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          setOrders(data.orders);
        } else {
          setError(data.message);
        }
      })
      .catch((error) => {
        setError("Error fetching partial pay orders: " + error.message);
      });
  };

  const handleEditClick = (id, amountReceived) => {
    setEditRowId(id);
    setEditAmountReceived(amountReceived);
  };

  const handleSaveClick = (id) => {
    const updatedOrder = orders.find((order) => order.id === id);
    const updatedBalance = updatedOrder.total_price - editAmountReceived;
    fetch(
      `https://kamalicrackers.com/ijwyshs_kamali_backend/update_partial_order.php`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id,
          amount_received: editAmountReceived,
          balance: updatedBalance,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setOrders(
            orders.map((order) =>
              order.id === id
                ? {
                    ...order,
                    amount_received: editAmountReceived,
                    balance: updatedBalance,
                  }
                : order
            )
          );
          setEditRowId(null);
          setEditAmountReceived(null);
        } else {
          setError(data.message);
        }
      })
      .catch((error) => {
        setError("Error updating order: " + error.message);
      });
  };

  const handleAmountReceivedChange = (event) => {
    setEditAmountReceived(Number(event.target.value));
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const filteredOrders = orders.filter(
    (order) =>
      order.id.toString().includes(searchQuery) ||
      order.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      order.phone.toString().includes(searchQuery)
  );

  const columns = [
    { field: "id", headerName: "Order ID", flex: 1 },
    { field: "name", headerName: "Customer Name", flex: 1 },
    { field: "phone", headerName: "Customer Phone", flex: 1 },
    { field: "total_price", headerName: "Total Price", flex: 1 },
    {
      field: "amount_received",
      headerName: "Amount Received",
      flex: 1,
      renderCell: (params) => {
        return editRowId === params.row.id ? (
          <input
            type="number"
            value={editAmountReceived}
            onChange={handleAmountReceivedChange}
          />
        ) : (
          params.value
        );
      },
    },
    { field: "balance", headerName: "Balance", flex: 1 },
    { field: "payment_mode", headerName: "Payment Mode", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        return editRowId === params.row.id ? (
          <IconButton onClick={() => handleSaveClick(params.row.id)}>
            <SaveIcon />
          </IconButton>
        ) : (
          <IconButton
            onClick={() =>
              handleEditClick(params.row.id, params.row.amount_received)
            }
          >
            <EditIcon />
          </IconButton>
        );
      },
    },
  ];

  if (error) {
    return <Typography variant="h6">{error}</Typography>;
  }

  return (
    <Box
      m="20px"
      ml="4%"
      sx={{
        // Responsive styles
        "@media (max-width: 768px)": {
          marginLeft: "5%",
          margin: "15px",
        },
        "@media (max-width: 480px)": {
          margin: "12px",
          marginLeft: "3.5%",
          marginTop: "7%",
        },
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <IconButton onClick={handleBackClick} id="partialpay">
          <ArrowBackIcon />
        </IconButton>
        <Typography
          variant="h4"
          gutterBottom
          style={{ flexGrow: 1, textAlign: "center", marginLeft: "-48px" }}
        >
          Partial Pay Orders
        </Typography>
      </Box>
      <TextField
        variant="outlined"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        fullWidth
        margin="normal"
      />
      <Box
        sx={{
          height: "calc(100vh - 155px)",
          width: "100%",
          "@media (max-width: 768px)": {
            height: "calc(100vh - 120px)", // Adjusted height for tablets
          },
          "@media (max-width: 480px)": {
            height: "calc(100vh - 205px)", // Adjusted height for mobile
          },
        }}
      >
        <DataGrid
          rows={filteredOrders}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          disableSelectionOnClick
        />
      </Box>
    </Box>
  );
};

export default PartialPay;
