import React from "react";
import Crackers_making1 from "../../../Assets/Crackers_making1.avif";
import Crackers_making2 from "../../../Assets/Crackers_making2.jpg";
import Crackers_making3 from "../../../Assets/Crackers_making3.png";
import "./Crackers_Made.css";

function Crackers_Made() {
  return (
    <div className="Crackers_making">
      <h3 className="ba-title">
        <span>How Fireworks Are Made</span>
      </h3>
      <div className="ba-section">
        <div className="ba-image-content">
          <img src={Crackers_making1} alt="Doctor Group" className="ba-image1" />
        </div>

        <div className="ba-text-content">
          <p className="ba-description">
            Workers are deeply immersed in processing grey chemical powder,
            essential for firework production. This hazardous material coats
            every surface, requiring meticulous handling. Their focus and
            precision ensure safety and quality, reflecting the critical nature
            of their role in creating spectacular pyrotechnics.
          </p>
        </div>
      </div>
      <div className="ba-section zigzag">
        <div className="ba-image-content">
          <img src={Crackers_making2} alt="Doctor Group" className="ba-image2" />
        </div>

        <div className="ba-text-content">
          <p className="ba-description1">
            In the next phase, workers carefully pack the fireworks to prevent
            accidental ignition and maintain integrity during transportation and
            storage. Their methodical approach and attention to detail highlight
            their commitment to safety and quality assurance.
          </p>
        </div>
      </div>
      <div className="ba-section">
        <div className="ba-image-content">
          <img src={Crackers_making3} alt="Doctor Group" className="ba-image1" />
        </div>

        <div className="ba-text-content">
          <p className="ba-description ba-description2">
            The culmination of their efforts is seen during celebrations with
            dazzling displays of light and sound. The vibrant explosions are a
            testament to the workers' skill and dedication. Their meticulous
            work allows communities to celebrate with awe-inspiring shows,
            showcasing their craftsmanship and dedication.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Crackers_Made;
