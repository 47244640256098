import React, { useState, useEffect, useRef } from "react";
import SearchInput from "./SearchInput";
import BillDetails from "./BillDetails";
import { useNavigate } from "react-router-dom";
import "./Pos.css";

const POSContent = ({
  tabId,
  handleSaveOrder,
  handleSelectItem,
  calculateBillDetails,
  resetContent,
  handleNewTab,
  handleCloseTab
}) => {
  const [focusedRow, setFocusedRow] = useState(null);
  const searchInputRef = useRef(null); // Reference for SearchInput component
  const customerDetailsRef = useRef(null); // Reference for Customer Details input field
  const amountReceivedRef = useRef(null); // Reference for Amount Received input field
  const navigate = useNavigate();

  useEffect(() => {
    if (resetContent) {
      const tableBody = document.getElementById(`item-table-${tabId}`);
      tableBody.innerHTML = ""; // Clear the table rows
      calculateBillDetails(tabId); // Recalculate bill details
    }
  }, [resetContent, tabId, calculateBillDetails]);

  useEffect(() => {
    const handleKeydown = (event) => {
      const tableBody = document.getElementById(`item-table-${tabId}`);
      if (!tableBody) return;

      const rows = Array.from(tableBody.rows);
      if (rows.length === 0) return;

      if (event.key === "ArrowDown") {
        event.preventDefault();
        if (focusedRow === null) {
          setFocusedRow(0);
        } else {
          setFocusedRow((prev) => Math.min(prev + 1, rows.length - 1));
        }
      }

      if (event.key === "ArrowUp") {
        event.preventDefault();
        if (focusedRow !== null) {
          setFocusedRow((prev) => Math.max(prev - 1, 0));
        }
      }

      if (event.key === "Delete" && focusedRow !== null) {
        event.preventDefault();
        const rowToDelete = rows[focusedRow];
        if (rowToDelete) {
          tableBody.removeChild(rowToDelete);
          setFocusedRow(null);
          updateSerialNumbers(tableBody);
          calculateBillDetails(tabId);
        }
      }

      if (event.ctrlKey && event.key === "q") {
        event.preventDefault();
        enableQuantityEditing(); // Call function to enable quantity editing
      }

      if (event.key === "Enter" && focusedRow !== null) {
        event.preventDefault();
        const row = rows[focusedRow];
        if (row) {
          const qtyElement = row.cells[3].querySelector(".qty-input");
          if (qtyElement) {
            const newQty = parseInt(qtyElement.innerText.trim(), 10);
            const pricePerUnit = parseFloat(
              row.cells[4].querySelector(".price-input").innerText.trim()
            );
            const totalElement = row.cells[6];

            if (!isNaN(newQty) && !isNaN(pricePerUnit)) {
              qtyElement.contentEditable = "false";
              const total = newQty * pricePerUnit;
              totalElement.innerText = total.toFixed(2);
              calculateBillDetails(tabId);
            }
          }
        }
      }

      if (event.ctrlKey && event.key === "+") {
        event.preventDefault();
        handleNewTab();
      }

      if (event.ctrlKey && event.key === "f") {
        event.preventDefault();
        // Trigger search input focus
        if (searchInputRef.current) {
          searchInputRef.current.focus();
        }
      }

      if (event.ctrlKey && event.key === "c") {
        event.preventDefault();
        // Focus on customer details input field
        if (customerDetailsRef.current) {
          customerDetailsRef.current.focus();
        }
      }

      if (event.ctrlKey && event.key === "a") {
        event.preventDefault();
        // Focus on amount received input field
        if (amountReceivedRef.current) {
          amountReceivedRef.current.focus();
          amountReceivedRef.current.readOnly = false; // Allow editing
        }
      }

      if (event.ctrlKey && event.key === "s") {
        event.preventDefault();
        handleSaveButtonClick(); // Trigger save action
      }
    };

    window.addEventListener("keydown", handleKeydown);

    return () => {
      window.removeEventListener("keydown", handleKeydown);
    };
  }, [focusedRow, tabId, calculateBillDetails, handleNewTab]);

  useEffect(() => {
    const tableBody = document.getElementById(`item-table-${tabId}`);
    if (tableBody) {
      const rows = Array.from(tableBody.rows);
      rows.forEach((row, index) => {
        if (index === focusedRow) {
          row.classList.add("focused-row");
        } else {
          row.classList.remove("focused-row");
        }
      });
    }
  }, [focusedRow, tabId]);

  const updateSerialNumbers = (tableBody) => {
    Array.from(tableBody.rows).forEach((row, index) => {
      row.cells[0].innerText = index + 1; // Assuming serial number is in the first cell
    });
  };

  const enableQuantityEditing = () => {
    const tableBody = document.getElementById(`item-table-${tabId}`);
    if (tableBody && focusedRow !== null) {
      const rows = Array.from(tableBody.rows);
      const row = rows[focusedRow];
      if (row) {
        const qtyElement = row.cells[3].querySelector(".qty-input");
        if (qtyElement) {
          qtyElement.contentEditable = "true";
          qtyElement.focus();
        }
      }
    }
  };

  const focusAmountReceivedField = () => {
    if (amountReceivedRef.current) {
      amountReceivedRef.current.focus();
      amountReceivedRef.current.readOnly = false; // Allow editing
    }
  };

  const handleSaveButtonClick = () => {
    handleSaveOrder(tabId);
  };

  const handlePartialPayClick = () => {
    navigate("/ijwyshs_admin/partial-pay");
  };

  const handleBillHistoryClick = () => {
    navigate("/ijwyshs_admin/bill-history");
  };

  const handleTabClose = () => {
    handleCloseTab(tabId); // Call the passed handleCloseTab function
  };

  return (
    <div className="pos-tab-content" id={`pos-tab-${tabId}`}>
      <div className="left-panel">
        <SearchInput
          ref={searchInputRef} // Pass ref to SearchInput
          onSearch={(item) => handleSelectItem(item, tabId)}
        />
        <div className="table-container">
          <table className="item-table">
            <thead>
              <tr>
                <th className="column-header">#</th>
                <th className="column-header">ITEM CODE</th>
                <th className="column-header">ITEM NAME</th>
                <th className="column-header">QTY</th>
                <th className="column-header">PRICE/UNIT(₹)</th>
                <th className="column-header">MRP(₹)</th>
                <th className="column-header">TOTAL(₹)</th>
                <th className="column-header">ACTION</th>
              </tr>
            </thead>
            <tbody id={`item-table-${tabId}`}>
              {/* Rows will be added dynamically */}
            </tbody>
          </table>
        </div>
        <div className="buttons">
          <button className="blue-button" onClick={handleNewTab}>
            New Tab [ctrl + '+']
          </button>
          <button className="blue-button" onClick={handleTabClose}>
            Close Tab [ctrl + '-']
          </button>{" "}
          <button
            className="blue-button"
            onClick={() => searchInputRef.current.focus()}
          >
            Search [ctrl + F]
          </button>
          <button className="blue-button" onClick={enableQuantityEditing}>
            Change Qty [ctrl + Q]
          </button>
          <button
            className="blue-button"
            onClick={() => customerDetailsRef.current.focus()}
          >
            Customer [ctrl + C]
          </button>
          <button className="blue-button" onClick={focusAmountReceivedField}>
            Amount Field [ctrl + A]
          </button>
          {/* Updated button */}
          <button className="blue-button" onClick={handlePartialPayClick}>
            Partial Pay [ctrl + P]
          </button>
          <button className="blue-button" onClick={handleBillHistoryClick}>
            Bill History [ctrl + B]
          </button>
        </div>
      </div>
      <div className="right-panel">
        <BillDetails
          tabId={tabId}
          handleSaveOrder={handleSaveOrder}
          calculateBillDetails={calculateBillDetails}
          customerDetailsRef={customerDetailsRef} // Pass the ref to BillDetails component
          amountReceivedRef={amountReceivedRef} // Pass the ref to BillDetails component
        />
      </div>
    </div>
  );
};

export default POSContent;
