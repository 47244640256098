import React from "react";
import "./App.css";
import { Routes, Route, useLocation} from "react-router-dom";
import Header from "./Components/CrackersWeb/Home/Header";
import { CartProvider } from "./Components/CrackersWeb/Shop/CartContext";
import { FavoriteProvider } from "./Components/CrackersWeb/Shop/FavoriteContext";
import Carousel from "./Components/CrackersWeb/Home/Carousel";
import CategoriesGrid from "./Components/CrackersWeb/Home/CategoriesGrid";
import CategoryHeader from "./Components/CrackersWeb/Home/CategoryHeader";
import ContactImage from "./Components/CrackersWeb/Home/ContactImage";
import ShopPage from "./Components/CrackersWeb/Shop/ShopPage";
import QuickBuy from "./Components/CrackersWeb/QuickBuy/QuickBuy";
import AdminPage from "./Components/AdminPage";
import About from "./Components/CrackersWeb/About/About";
import Contact from "./Components/CrackersWeb/Contact/Contact";
import CartPage from "./Components/CrackersWeb/Home/CartPage";
import DeliveryDetails from "./Components/CrackersWeb/Home/DeliveryDetails";
import ProductDetail from "./Components/CrackersWeb/Shop/ProductDetail";
import Carousel2 from "./Components/CrackersWeb/Home/Carousel2";
import Icons from "./Components/CrackersWeb/Home/Icons";
import FavoritesPage from "./Components/CrackersWeb/Home/FavoritesPage"; // Import FavoritesPage
import { Helmet } from "react-helmet";
import FireworksEffect from './Components/CrackersWeb/Home/FireworksEffect';
import ActPopUp from "./Components/CrackersWeb/Home/ActPopUp";

function App() {
  const location = useLocation();
  return (
    <CartProvider>
      <FavoriteProvider>
      {!location.pathname.startsWith("/ijwyshs_admin") && <ActPopUp />}
      <div className="App">
          <Helmet>
            <title>Kamali Crackers - Home</title>
            <meta
              name="description"
              content="Welcome to Kamali Crackers - your ultimate destination for buying premium quality crackers and fireworks online in Tamil Nadu. Explore our wide range of products and shop securely with fast delivery options."
            />
            <meta
              name="keywords"
              content="Kamali crackers, buy crackers online Sivakasi, online crackers Sivakasi, Sivakasi fireworks, Diwali crackers online Sivakasi, wholesale crackers Sivakasi, Sivakasi firecrackers, order crackers online Sivakasi, best crackers Sivakasi, cheap crackers Sivakasi, crackers delivery Sivakasi, Kamali crackers shop online, festival fireworks Sivakasi, Kamali crackers wholesale, crackers online Tamil Nadu, fireworks online Sivakasi, Kamali crackers for Diwali, bulk crackers Sivakasi, discount crackers Sivakasi, crackers wholesale Sivakasi, Sivakasi fireworks for festivals, buy fireworks online Sivakasi, Kamali crackers sale, Sivakasi gift box crackers, Sivakasi fancy crackers"
            />
            <meta name="Sankarapandi" content="Kamali Crackers" />
            <meta property="og:title" content="Kamali Crackers - Home" />
            <meta
              property="og:description"
              content="Welcome to Kamali Crackers - your ultimate destination for buying premium quality crackers and fireworks online in Tamil Nadu. Explore our wide range of products and shop securely with fast delivery options."
            />
            <meta property="og:image" content="/brand-image.jpg" />
            <meta
              property="og:url"
              content="https://kamalicrackers.com"
            />
            <meta property="og:type" content="website" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Kamali Crackers - Home" />
            <meta
              name="twitter:description"
              content="Welcome to Kamali Crackers - your ultimate destination for buying premium quality crackers and fireworks online in Tamil Nadu. Explore our wide range of products and shop securely with fast delivery options."
            />
            <meta name="twitter:image" content="/brand-image.jpg" />
          </Helmet>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Header />
                  <FireworksEffect /> <Icons /> <Carousel /> <CategoryHeader />{" "}
                  <CategoriesGrid /> <Carousel2 /> <ContactImage />
                </>
              }
            />
            <Route
              path="/home"
              element={
                <>
                  <Header /> <FireworksEffect /> <Icons /> <Carousel />{" "}
                  <CategoryHeader /> <CategoriesGrid /> <Carousel2 />{" "}
                  <ContactImage />
                </>
              }
            />
            <Route
              path="/shop"
              element={
                <>
                  <Header /> <ShopPage />
                </>
              }
            />
            <Route
              path="/quickBuy"
              element={
                <>
                  <Header />
                  <QuickBuy />
                </>
              }
            />
            <Route
              path="/about"
              element={
                <>
                  <Header />
                  <About />
                </>
              }
            />
            <Route
              path="/contact"
              element={
                <>
                  <Header />
                  <Contact />
                </>
              }
            />
            <Route
              path="/cart"
              element={
                <>
                  <Header />
                  <CartPage />
                </>
              }
            />
            <Route
              path="/delivery-details"
              element={
                <>
                  <Header />
                  <DeliveryDetails />
                </>
              }
            />
            <Route
              path="/product/:id"
              element={
                <>
                  <Header /> <ProductDetail />
                </>
              }
            />
            <Route
              path="/favorites"
              element={
                <>
                  <Header /> <FavoritesPage />
                </>
              }
            />
            <Route path="/ijwyshs_admin/*" element={<AdminPage />} />
          </Routes>
        </div>
      </FavoriteProvider>
    </CartProvider>
  );
}

export default App;