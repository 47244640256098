import React from "react";
import Logo from "../../../Assets/DashboardLogo.png";
import about from "../../../Assets/about.png"
import SolutionStep from "./SolutionStep";
import "./About.css";
import { Helmet } from "react-helmet";

function About() {
  return (
    <div className="AP-about-section" id="about">
      <Helmet>
        <title>Kamali Crackers - About Us</title>
        {/* Meta Description */}
        <meta
          name="description"
          content="Learn more about Kamali Crackers, your trusted destination for high-quality fireworks at wholesale prices in Tamil Nadu. Discover our range of products and our commitment to making your celebrations unforgettable."
        />

        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="Kamali crackers, buy crackers online Sivakasi, online crackers Sivakasi, Sivakasi fireworks, Diwali crackers online Sivakasi, wholesale crackers Sivakasi, Sivakasi firecrackers, order crackers online Sivakasi, best crackers Sivakasi, cheap crackers Sivakasi, crackers delivery Sivakasi, Kamali crackers shop online, festival fireworks Sivakasi, Kamali crackers wholesale, crackers online Tamil Nadu, fireworks online Sivakasi, Kamali crackers for Diwali, bulk crackers Sivakasi, discount crackers Sivakasi, crackers wholesale Sivakasi, Sivakasi fireworks for festivals, buy fireworks online Sivakasi, Kamali crackers sale, Sivakasi gift box crackers, Sivakasi fancy crackers"
        />

        {/* Author */}
        <meta name="author" content="Sankarapandi, Kamali Crackers" />

        {/* Open Graph Meta Tags for Social Media */}
        <meta property="og:title" content="Kamali Crackers - About Us" />
        <meta
          property="og:description"
          content="Learn more about Kamali Crackers, your trusted destination for high-quality fireworks at wholesale prices in Tamil Nadu. Discover our range of products and our commitment to making your celebrations unforgettable."
        />
        <meta property="og:image" content="/brand-image.jpg" />
        <meta property="og:url" content="https://www.kamalicrackers.com/about" />
        <meta property="og:type" content="website" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Kamali Crackers - About Us" />
        <meta
          name="twitter:description"
          content="Learn more about Kamali Crackers, your trusted destination for high-quality fireworks at wholesale prices in Tamil Nadu. Discover our range of products and our commitment to making your celebrations unforgettable."
        />
        <meta name="twitter:image" content="/brand-image.jpg" />
      </Helmet>

      <div className="AP-about-image-content">
        <img src={about} alt="Logo image" className="AP-about-image1" />
      </div>

      <div className="AP-about-text-content">
        <h3 className="AP-about-title">
          <span>About Us</span>
        </h3>
        <p className="AP-about-description">
          Welcome to Kamali Crackers, your go-to destination for
          high-quality fireworks at wholesale prices. Located in the heart of
          Tamil Nadu, we offer a wide selection of the latest fancy crackers and
          exclusive gift boxes to illuminate your celebrations.
        </p>

        <h4 className="AP-about-text-title">Your Solutions</h4>

        <SolutionStep
          title="Explore Our Range"
          description="Discover a variety of fireworks from top brands like Sony, Standard, Vadivel, and Anil. Whether it's Diwali, New Year, or any special occasion, our collection has the perfect options to make your event unforgettable."
        />

        <SolutionStep
          title="Plan Your Celebration"
          description="Visit us at our outlet to explore our extensive selection. Our friendly staff will assist you in finding the best deals and the ideal fireworks to enhance your festivities."
        />

        <SolutionStep
          title="Light Up Your Moments"
          description="At Kamali Crackers, we believe in creating experiences filled with joy and memorable moments. Join us and let our fireworks add sparkle to your celebrations."
        />
      </div>
    </div>
  );
}

export default About;
