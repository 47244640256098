import React, { useState, useEffect } from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { tokens } from "../theme";

const RecentOrders = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // State for recent orders
  const [recentOrders, setRecentOrders] = useState([]);

  useEffect(() => {
    const fetchRecentOrders = async () => {
      try {
        const response = await fetch(
          "https://kamalicrackers.com/ijwyshs_kamali_backend/get_recent_orders.php"
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        if (data.status === "success") {
          setRecentOrders(data.recent_orders);
        } else {
          console.error("Error: ", data.message);
        }
      } catch (error) {
        console.error("Error fetching recent orders:", error);
      }
    };

    fetchRecentOrders();
  }, []);

  return (
    <Box
      gridColumn={isMobile ? "span 12" : "span 4"} // Adjust column span based on mobile view
      gridRow="span 2"
      backgroundColor="white"
      overflow="auto"
      sx={{ height: "400px" }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        borderBottom={`4px solid ${colors.primary[500]}`}
        colors={colors.grey[100]}
        p="15px"
      >
        <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
          Recent Orders
        </Typography>
      </Box>
      {recentOrders
        .sort((a, b) => b.id - a.id) // Sort orders by id in descending order
        .map((order) => (
          <Box
            key={order.id}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            p="15px"
            position="relative" // Set relative position to parent container
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start" // Align to the left
              width="40%" // Set width to 40% of the container
              sx={{ wordWrap: "break-word", textAlign: "left" }} // Ensure text is aligned left
            >
              <Typography color="#333333" variant="h5" fontWeight="600">
                Order #{order.id}
              </Typography>
              <Typography color={colors.grey[100]}>
                {order.customer_name}
              </Typography>
            </Box>
            <Box
              position="absolute" // Position the date absolutely
              left="50%" // Position it at the center horizontally
              transform="translateX(-50%)" // Center it using transform
              color={colors.grey[100]}
            >
              {new Date(order.date).toLocaleDateString("en-US")}
            </Box>
            <Box
              backgroundColor="#17a2b8"
              p="5px 10px"
              borderRadius="4px"
              color="#333333"
            >
              ₹ {Math.round(order.total_amount)}
            </Box>
          </Box>
        ))}
    </Box>
  );
};

export default RecentOrders;
