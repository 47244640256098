import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import "./Item.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import FloatingPlaceholderInput from "./FloatingPlaceholderInput"; // Import the updated component

function Item() {
  const theme = useTheme();
  const [partyName, setPartyName] = useState("");
  const [itemCode, setItemCode] = useState("");
  const [category, setCategory] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [discountOnSale, setDiscountOnSale] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState("");
  const [stock, setStock] = useState("");
  const [image, setImage] = useState(null);
  const [newCategory, setNewCategory] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [categories, setCategories] = useState([]);
  const [itemCodeError, setItemCodeError] = useState("");

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const handleItemCodeChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setItemCode(value);
      setItemCodeError(""); // Clear error message if input is valid
    } else {
      setItemCodeError("Item Code should contain only numbers.");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (itemCodeError) {
      alert("Please correct the errors before submitting.");
      return;
    }

    const formData = new FormData();
    formData.append("id", itemCode);
    formData.append("name", partyName);
    formData.append("category", category);
    formData.append("originalPrice", salePrice);
    formData.append("discountedPrice", discountOnSale);
    formData.append("discount", discountPercentage);
    formData.append("stock", stock);
    if (image) {
      formData.append("image", image);
    }

    console.log("Submitting item:", formData);

    try {
      const response = await fetch("https://kamalicrackers.com/ijwyshs_kamali_backend/save_item.php", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log("Response data:", responseData);

      if (responseData.success) {
        alert("Item saved successfully");
        // Reset form fields
        resetForm();
      } else {
        alert(`Failed to save item: ${responseData.message}`);
      }
    } catch (error) {
      console.error("Error saving item:", error);
      alert("Error saving item");
    }
  };

  const handleAddNewCategory = async () => {
    try {
      const response = await fetch(
        "https://kamalicrackers.com/ijwyshs_kamali_backend/save_category.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name: newCategory }),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.success) {
          setCategories((prevCategories) => [...prevCategories, newCategory]);
          setCategory(newCategory);
          setNewCategory("");
          setOpenDialog(false);
        } else {
          alert(`Failed to add category: ${responseData.message}`);
        }
      } else {
        alert(`Failed to add category: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error adding category:", error);
      alert("Error adding category");
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch("https://kamalicrackers.com/ijwyshs_kamali_backend/save_item.php", {
        method: "GET",
      });
      const responseData = await response.json();
      if (responseData.success) {
        setCategories(responseData.categories);
      } else {
        alert(`Failed to fetch categories: ${responseData.message}`);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
      alert("Error fetching categories");
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleDeleteCategory = async (categoryToDelete) => {
    try {
      const response = await fetch(
        "https://kamalicrackers.com/ijwyshs_kamali_backend/delete_category.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name: categoryToDelete }),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.success) {
          // Remove the deleted category from the state
          setCategories((prevCategories) =>
            prevCategories.filter((cat) => cat !== categoryToDelete)
          );
          if (category === categoryToDelete) {
            setCategory(""); // Reset category selection if the deleted one was selected
          }
          alert("Category deleted successfully");
        } else {
          alert(`Failed to delete category: ${responseData.message}`);
        }
      } else {
        alert(`Failed to delete category: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error deleting category:", error);
      alert("Error deleting category");
    }
  };

  useEffect(() => {
    if (salePrice && discountOnSale) {
      const salePriceNumber = parseFloat(salePrice);
      const discountOnSaleNumber = parseFloat(discountOnSale);
      if (
        !isNaN(salePriceNumber) &&
        !isNaN(discountOnSaleNumber) &&
        salePriceNumber > 0
      ) {
        const discountPercent =
          ((salePriceNumber - discountOnSaleNumber) / salePriceNumber) * 100;
        setDiscountPercentage(discountPercent.toFixed(2)); // setting to 2 decimal places
      } else {
        setDiscountPercentage("");
      }
    } else {
      setDiscountPercentage("");
    }
  }, [salePrice, discountOnSale]);

  // Function to reset form fields
  const resetForm = () => {
    setPartyName("");
    setItemCode("");
    setCategory("");
    setSalePrice("");
    setDiscountOnSale("");
    setDiscountPercentage("");
    setStock("");
    setImage(null);
  };

  return (
    <Box className="add-item-form">
      <Box className="form-container">
        <form onSubmit={handleSubmit}>
          <Box className="container1">
            <Box className="input-fields">
              <FloatingPlaceholderInput
                label="Item Name*"
                value={partyName}
                onChange={(e) => setPartyName(e.target.value)}
                required
              />
              <Box className="input-container">
                <Select
                  value={category}
                  onChange={(e) => {
                    if (e.target.value === "add_new_category") {
                      setOpenDialog(true);
                    } else {
                      setCategory(e.target.value);
                    }
                  }}
                  displayEmpty
                  required
                  fullWidth
                >
                  <MenuItem
                    value="add_new_category"
                    style={{ color: "#007bff", fontWeight: "bold" }}
                  >
                    Add New Category
                  </MenuItem>

                  {categories.map((cat, index) => (
                    <MenuItem key={index} value={cat}>
                      {cat}
                      {category !== cat && ( // Conditional rendering
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent the menu from closing
                            handleDeleteCategory(cat);
                          }}
                          style={{
                            marginLeft: "auto",
                            color: "red",
                            fontSize: "13px",
                          }}
                        >
                          <FontAwesomeIcon icon={faTrashCan} />
                        </IconButton>
                      )}
                    </MenuItem>
                  ))}
                </Select>
                <label
                  className={`input-placeholder ${category ? "active" : ""}`}
                >
                  Select Category
                </label>
              </Box>

              <FloatingPlaceholderInput
                label="Item Code"
                value={itemCode}
                onChange={handleItemCodeChange}
                required
                errorMessage={itemCodeError} // Pass the error message to the component
              />
              <Box className="input-container">
                <input
                  accept="image/png, image/jpeg, image/jpg"
                  style={{ display: "none" }}
                  id="contained-button-file"
                  type="file"
                  onChange={handleImageChange}
                />
                <label htmlFor="contained-button-file">
                  <Button variant="contained" color="primary" component="span">
                    Add Image
                  </Button>
                </label>
                {image && (
                  <Box className="image-preview">
                    <img
                      src={URL.createObjectURL(image)}
                      alt="Selected"
                      style={{
                        width: "50px",
                        height: "50px",
                        marginLeft: "10px",
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box className="heading-container">
            <h2 className="pricing-heading">Pricing / Stock</h2>
            <Box className="percentage">
              <FloatingPlaceholderInput
                label="Stock Quantity"
                value={stock}
                onChange={(e) => setStock(e.target.value)}
                type="number"
                required
              />
            </Box>
          </Box>
          <br />
          <Box className="percentage">
            <FloatingPlaceholderInput
              label="Sale Price"
              value={salePrice}
              onChange={(e) => setSalePrice(e.target.value)}
              type="number"
              hasRupeeSymbol={true}
            />
          </Box>
          <br />
          <Box className="percentage">
            <FloatingPlaceholderInput
              label="Discount Price"
              value={discountOnSale}
              onChange={(e) => setDiscountOnSale(e.target.value)}
              type="number"
              hasRupeeSymbol={true}
            />
          </Box>
          <br />
          <Box className="percentage">
            <FloatingPlaceholderInput
              label="Discount Percentage"
              value={discountPercentage}
              onChange={(e) => setDiscountPercentage(e.target.value)}
              hasRupeeSymbol={false}
              disabled
            />
          </Box>
          <br />
          <Box className="form-actions">
            <Button onClick={resetForm}>Reset</Button>
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
          </Box>
        </form>
      </Box>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Add New Category</DialogTitle>
        <DialogContent>
          <TextField
            label="Category Name"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button
            onClick={handleAddNewCategory}
            variant="contained"
            color="primary"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Item;
