import React, { useState } from 'react';
import './Login.css';
//import Registration from './Register';
import Modal from 'react-modal';
import Register from './Register';

Modal.setAppElement('#root'); // For accessibility

const LoginPage = ({ onAdminLoginClick }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="LP-app-container">
      <div className="LP-auth-box">
        <h2 className="LP-auth-title">Login</h2>
        <form className="LP-auth-form">
          <input type="text" className="LP-auth-input" placeholder="Username" />
          <input type="password" className="LP-auth-input" placeholder="Password" />
          <button className="LP-auth-button">Login</button><br></br>
          <p className='LP-auth-register'>Don't have an account?  <a href="#" onClick={openModal}><b>Register here</b></a></p><br></br><hr></hr><br></br>
          <a href="#" onClick={onAdminLoginClick}><b className='LP-auth-admin'>Admin Login</b></a>
        </form>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Register"
        className="LP-modal"
        overlayClassName="LP-modal-overlay"
      >
        <button onClick={closeModal} className="LP-close-button">Close</button>
        <Register />
      </Modal>
    </div>
  );
};

export default LoginPage;
