import React, { useContext, useState } from "react";
import { CartContext } from "../Shop/CartContext";
import "./DeliveryDetails.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Confetti from "../Home/Confetti";
import CustomModal from "./CustomModal";

const DeliveryDetails = () => {
  const { cart, totalPrice } = useContext(CartContext);
  const [showConfetti, setShowConfetti] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const totalItems = cart.reduce((total, item) => total + item.quantity, 0);
  const totalDiscounts = cart.reduce(
    (total, item) =>
      total + (item.original_price - item.discounted_price) * item.quantity,
    0
  );

  const handlePlaceOrder = async (event) => {
    event.preventDefault();

    if (totalPrice < 2000) {
      setAlertMessage(
        "Please add items worth ₹2000 or more to place an order."
      );
      setShowModal(true);
      return;
    }

    if (!phone || phone.length < 10) {
      setPhoneError("Please enter a valid phone number");
      return;
    } else {
      setPhoneError("");
    }

    const formData = new FormData(event.target);
    formData.append("totalItems", totalItems);
    formData.append("subTotal", totalPrice.toFixed(2));
    formData.append(
      "totalDiscounts",
      totalDiscounts.toFixed(2)
    );
    formData.append("totalPrice", totalPrice.toFixed(2));
    formData.append("items", JSON.stringify(cart));

    try {
      const response = await fetch("https://kamalicrackers.com/ijwyshs_kamali_backend/place_order.php", {
        method: "POST",
        body: formData,
      });

      const data = await response.json();

      console.log("Response data:", data);

      if (data.status === "success") {
        setAlertMessage("Your order was placed successfully.");
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 4000);
      } else {
        console.log("Error message:", data.message);
        setAlertMessage("Failed to place order. Please try again later.");
      }
    } catch (error) {
      console.error("Error:", error);
      setAlertMessage("Failed to place order. Please try again later.");
    }

    setShowModal(true);
  };

  return (
    <div className="deliveryDetails1">
      <CustomModal
        show={showModal}
        message={alertMessage}
        onClose={() => setShowModal(false)}
      />
      <h3>🚛Delivery Address</h3>
      <form onSubmit={handlePlaceOrder}>
        <input
          type="text"
          name="name"
          placeholder="Name *"
          required
          style={{ marginTop: "15px" }}
        />
        <div className="phoneInputContainer">
          <PhoneInput
            country={"in"}
            value={phone}
            onChange={(value) => {
              setPhone(value);
              if (value.length >= 10) {
                setPhoneError("");
              }
            }}
            inputProps={{
              name: "phone",
              required: true,
              autoFocus: true,
            }}
            containerClass="phoneInput"
          />
          {phoneError && <p className="errorMessage1">{phoneError}</p>}
        </div>
        <textarea name="address" placeholder="Address" required></textarea>
        <div className="priceDetails1">
          <h3>Price Details</h3>
          <div className="priceRow1">
            <span>Sub Total</span>
            <span>₹{totalPrice.toFixed(2)}</span>
          </div>
          <div className="priceRow1">
            <span>Discounts</span>
            <span>₹{totalDiscounts.toFixed(2)}</span>
          </div>
          <div className="priceRow1">
            <span>Other Charges</span>
            <span>₹0.00</span>
          </div>
          <hr className="dummyLine1" />
          <div className="priceRow1 totalPay1">
            <span>To Pay</span>
            <span>₹{totalPrice.toFixed(2)}</span>
          </div>
          {totalPrice < 2000 && (
            <div className="minimumOrderMessage1">
              Add items worth ₹2000 or more to place an order.
            </div>
          )}
          <button type="submit" className="placeOrderButton1">
            Place Order
          </button>
        </div>
      </form>
      <Confetti run={showConfetti} />
    </div>
  );
};

export default DeliveryDetails;
