import React from 'react';
import './Register.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faLock, faKey } from '@fortawesome/free-solid-svg-icons';

const Register = () => {
  return (
    <section className="RP-vh-100 bg-light">
      <div className="RP-container h-100">
        <div className="RP-row d-flex justify-content-center align-items-center h-100">
          <div className="RP-col-lg-12 col-xl-11">
            <div className="RP-card text-black" style={{ borderRadius: '25px' }}>
              <div className="RP-card-body p-md-5">
                <div className="RP-row justify-content-center align-items-center">
                  <div className="RP-col-md-10 col-lg-6 col-xl-5 order-2 order-lg-1">
                    <p className="RP-text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">Sign up</p>
                    <form className="RP-mx-1 mx-md-4">
                      <div className="RP-fp-input-container">
                        <FontAwesomeIcon icon={faUser} className="RP-fa-lg me-3 fa-fw" />
                        <input type="text" id="form3Example1c" className="RP-form-control" placeholder="Your Name" />
                      </div>
                      <div className="RP-fp-input-container">
                        <FontAwesomeIcon icon={faEnvelope} className="RP-fa-lg me-3 fa-fw" />
                        <input type="email" id="form3Example3c" className="RP-form-control" placeholder="Your Email" />
                      </div>
                      <div className="RP-fp-input-container">
                        <FontAwesomeIcon icon={faLock} className="RP-fa-lg me-3 fa-fw" />
                        <input type="password" id="form3Example4c" className="RP-form-control" placeholder="Password" />
                      </div>
                      <div className="RP-fp-input-container">
                        <FontAwesomeIcon icon={faKey} className="RP-fa-lg me-3 fa-fw" />
                        <input type="password" id="form3Example4cd" className="RP-form-control" placeholder="Repeat your password" />
                      </div>
                      <div className="RP-form-check d-flex justify-content-center mb-5">
                        <input className="RP-form-check-input me-2" type="checkbox" value="" id="form2Example3c" />
                        <label className="RP-form-check-label" htmlFor="form2Example3">
                          I agree all statements in <a href="#!">Terms of service</a>
                        </label>
                      </div>
                      <div className="RP-d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                        <button type="button" className="RP-btn btn-primary btn-lg">Register</button>
                      </div>
                    </form>
                  </div>
                  <div className="RP-col-md-10 col-lg-6 col-xl-7 d-flex align-items-center justify-content-center order-1 order-lg-2">
                    <img src="https://www.pandiancrackers.com/images/bb.png" className="RP-img-fluid register-img" alt="Sample" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Register;
