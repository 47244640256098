import React, { useState } from "react";
import { Box, Button, TextField, Select, MenuItem } from "@mui/material";
import Navbar from "../customer/Navbar";
import "../customer/Add.css";

const FloatingPlaceholderInput = ({
  label,
  value,
  onChange,
  type = "text",
  textarea = false,
  hasButton = false,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => {
    if (value === "") setIsFocused(false);
  };

  return (
    <div className={`fp-input-containerFirst ${textarea ? "textarea" : ""}`}>
      {textarea ? (
        <textarea
          value={value}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={onChange}
        />
      ) : (
        <div className="fp-input-with-button">
          <input
            type={type}
            value={value}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={onChange}
          />
          {hasButton && (
            <>
              <button className="fp-info-button" type="button">
                i
                <div className="fp-tooltip">
                  Fill GSTIN to verify & fetch party data.
                </div>
              </button>
            </>
          )}
        </div>
      )}
      <label
        className={`fp-input-placeholder ${isFocused || value ? "active" : ""}`}
      >
        {label}
      </label>
    </div>
  );
};

const stateOptions = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry",
];

function Add() {
  const [partyName, setPartyName] = useState("");
  const [gstin, setGstin] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [gstType, setGstType] = useState("Unregistered/Consumer");
  const [state, setState] = useState("");
  const [email, setEmail] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const [enableShippingAddress, setEnableShippingAddress] = useState(false);

  const tabs = ["GST & Address", "Credit & Balance", "Additional Fields"];
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "GST & Address":
        return (
          <form onSubmit={handleSubmit}>
            <Box className="fp-line">
              <Box className="fp-line1">
                <Box className="fp-input-container">
                  <Select
                    value={gstType}
                    onChange={(e) => setGstType(e.target.value)}
                  >
                    <MenuItem value="Unregistered/Consumer">
                      Unregistered/Consumer
                    </MenuItem>
                    <MenuItem value="Registered">
                      Registered Business - Regular
                    </MenuItem>
                    <MenuItem value="Registered">
                      Registered Business - Composition
                    </MenuItem>
                  </Select>
                  <label className="fp-input-placeholder active">
                    GST Type
                  </label>
                </Box>
                <Box className="fp-input-container" id="Select">
                  <Select
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    className="SelectState"
                  >
                    <MenuItem value="" disabled></MenuItem>
                    {stateOptions.map((stateOption) => (
                      <MenuItem key={stateOption} value={stateOption}>
                        {stateOption}
                      </MenuItem>
                    ))}
                  </Select>
                  <label
                    className={`fp-input-placeholder ${
                      state ? "active" : ""
                    }`}
                  >
                    State
                  </label>
                </Box>
                <Box className="fb-email">
                  <FloatingPlaceholderInput
                    label="Email ID"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                  />
                </Box>
              </Box>
              <hr className="fp-vertical-line"></hr>
              <Box className="fp-line2">
                <FloatingPlaceholderInput
                  label="Billing Address"
                  value={billingAddress}
                  onChange={(e) => setBillingAddress(e.target.value)}
                  textarea
                />
                <Box className="fp-gst-address">
                  <label
                    className={`fp-enable-shipping ${
                      enableShippingAddress ? "active" : ""
                    }`}
                  >
                    <input
                      type="checkbox"
                      className="enable"
                      checked={enableShippingAddress}
                      onChange={(e) =>
                        setEnableShippingAddress(e.target.checked)
                      }
                    />
                    {enableShippingAddress
                      ? "- Disable Shipping Address"
                      : "+ Enable Shipping Address"}
                  </label>
                  {enableShippingAddress && (
                    <FloatingPlaceholderInput
                      label="Shipping Address"
                      value={shippingAddress}
                      onChange={(e) => setShippingAddress(e.target.value)}
                      textarea
                    />
                  )}
                </Box>
              </Box>
            </Box>
            <Box className="end">
              <hr></hr>
            </Box>
            <Box className="form-actions">
              <Button type="submit" variant="contained" color="primary">
                Save & New
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Save
              </Button>
            </Box>
          </form>
        );
      case "Credit & Balance":
        return <>{/* Add your Credit & Balance form fields here */}</>;
      case "Additional Fields":
        return <>{/* Add your Additional Fields form fields here */}</>;
      default:
        return null;
    }
  };

  return (
    <Box className="add-party-form">
      <h2>Add Customer</h2>
      <hr />
      <form onSubmit={handleSubmit}>
        <Box className="container1">
          <FloatingPlaceholderInput
            label="Party Name*"
            value={partyName}
            onChange={(e) => setPartyName(e.target.value)}
            required
          />
         
         <Box className="input-fields">
  <FloatingPlaceholderInput
    label="GSTIN"
    value={gstin}
    onChange={(e) => setGstin(e.target.value)}
    hasButton
  />
  <FloatingPlaceholderInput
    label="Phone Number"
    value={phoneNumber}
    onChange={(e) => setPhoneNumber(e.target.value)}
  />
</Box>
</Box>
</form>
<Navbar tabs={tabs} onTabClick={handleTabClick} />
{renderTabContent()}
</Box>
);
}

export default Add;
