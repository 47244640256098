import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DefaultImage from "../../Assets/DefaultImage.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import SearchIcon from "@mui/icons-material/Search";

const ItemList = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editedItem, setEditedItem] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [newCategory, setNewCategory] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchItems();
    fetchCategories();
  }, []);

  useEffect(() => {
    setFilteredItems(
      items.filter((item) =>
        Object.keys(item).some((key) =>
          [
            "id",
            "name",
            "category",
            "original_price",
            "discounted_price",
            "discount",
            "stock",
            "balance_stock",
          ].includes(key)
            ? item[key]
                ?.toString()
                .toLowerCase()
                .includes(searchQuery.toLowerCase())
            : false
        )
      )
    );
  }, [searchQuery, items]);

  const fetchItems = async () => {
    try {
      const response = await fetch("https://kamalicrackers.com/ijwyshs_kamali_backend/fetch_items.php");
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }
      setItems(data);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch(
        "https://kamalicrackers.com/ijwyshs_kamali_backend/fetch_categories.php"
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setCategories(data.map((category) => category.name));
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleEditClick = (id) => {
    setEditingIndex(id);
    const itemToEdit = items.find((item) => item.id === id);
    setEditedItem({ ...itemToEdit });
  };

  const handleDeleteClick = async (id) => {
    try {
      const response = await fetch("https://kamalicrackers.com/ijwyshs_kamali_backend/delete_item.php", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id }),
      });

      const result = await response.json();
      if (result.success) {
        setItems(items.filter((item) => item.id !== id));
      } else {
        console.error("Error deleting item:", result.message);
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedItem((prevItem) => ({
      ...prevItem,
      [name]: value,
    }));

    // Calculate discount percentage if both original_price and discounted_price are available
    if (name === "original_price" || name === "discounted_price") {
      const originalPrice = parseFloat(
        name === "original_price" ? value : editedItem.original_price || 0
      );
      const discountedPrice = parseFloat(
        name === "discounted_price" ? value : editedItem.discounted_price || 0
      );
      if (originalPrice > 0) {
        const discountPercentage =
          ((originalPrice - discountedPrice) / originalPrice) * 100;
        setEditedItem((prevItem) => ({
          ...prevItem,
          discount: discountPercentage.toFixed(2),
        }));
      }
    }
  };

  const handleImageChange = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  const handleSaveClick = async () => {
    const formData = new FormData();
    Object.keys(editedItem).forEach((key) => {
      formData.append(key, editedItem[key]);
    });
    if (selectedImage) {
      formData.append("image", selectedImage);
    }

    const url = "https://kamalicrackers.com/ijwyshs_kamali_backend/save_item1.php";
    const method = "POST";

    try {
      const response = await fetch(url, {
        method: method,
        body: formData,
      });
      const result = await response.json();
      if (result.success) {
        fetchItems();
        setEditingIndex(null);
        setEditedItem({});
        setSelectedImage(null);
      } else {
        console.error("Error updating/adding item:", result.message);
      }
    } catch (error) {
      console.error("Error updating/adding item:", error);
    }
  };

  const handleAddNewCategory = async () => {
    try {
      const response = await fetch(
        "https://kamalicrackers.com/ijwyshs_kamali_backend/save_category.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name: newCategory }),
        }
      );

      const result = await response.json();
      if (result.success) {
        setCategories([...categories, newCategory]);
        setEditedItem((prevItem) => ({ ...prevItem, category: newCategory }));
        setNewCategory("");
        setOpenDialog(false);
      } else {
        console.error("Error adding new category:", result.message);
      }
    } catch (error) {
      console.error("Error adding new category:", error);
    }
  };

  const handleDeleteCategory = async (categoryName) => {
    try {
      const response = await fetch(
        "https://kamalicrackers.com/ijwyshs_kamali_backend/delete_category.php",
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name: categoryName }),
        }
      );

      const result = await response.json();
      if (result.success) {
        console.log(`Category ${categoryName} deleted successfully.`);
        setCategories(
          categories.filter((category) => category !== categoryName)
        );
        // Optionally, refetch items if they are dependent on the deleted category
        // fetchItems();
      } else {
        console.error("Error deleting category:", result.message);
      }
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) =>
        editingIndex === params.row.id ? (
          <TextField
            name="name"
            value={editedItem.name || ""}
            onChange={handleInputChange}
            fullWidth
          />
        ) : (
          params.value
        ),
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      renderCell: (params) =>
        editingIndex === params.row.id ? (
          <Select
            name="category"
            value={editedItem.category || ""}
            onChange={(e) => {
              if (e.target.value === "add_new_category") {
                setOpenDialog(true);
              } else {
                handleInputChange(e);
              }
            }}
            fullWidth
          >
            <MenuItem
              value="add_new_category"
              style={{ color: "#007bff", fontWeight: "bold" }} // Change the color as needed
            >
              Add New Category
            </MenuItem>{" "}
            {categories.map((category, index) => (
              <MenuItem key={index} value={category}>
                {category}
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent the menu from closing
                    handleDeleteCategory(category);
                  }}
                  style={{
                    marginLeft: "auto", // Pushes the icon to the right
                    color: "red",
                    fontSize: "13px", // Changes the icon color to red
                  }}
                >
                  <FontAwesomeIcon icon={faTrashCan} />
                </IconButton>
              </MenuItem>
            ))}
          </Select>
        ) : (
          params.value
        ),
    },
    {
      field: "original_price",
      headerName: "Original Price",
      flex: 1,
      renderCell: (params) =>
        editingIndex === params.row.id ? (
          <TextField
            name="original_price"
            value={editedItem.original_price || ""}
            onChange={handleInputChange}
            fullWidth
          />
        ) : (
          params.value
        ),
    },
    {
      field: "discounted_price",
      headerName: "Discounted Price",
      flex: 1,
      renderCell: (params) =>
        editingIndex === params.row.id ? (
          <TextField
            name="discounted_price"
            value={editedItem.discounted_price || ""}
            onChange={handleInputChange}
            fullWidth
          />
        ) : (
          params.value
        ),
    },
    {
      field: "discount",
      headerName: "Discount (%)",
      flex: 1,
      renderCell: (params) =>
        editingIndex === params.row.id ? (
          <TextField
            name="discount"
            value={editedItem.discount || ""}
            onChange={handleInputChange}
            fullWidth
          />
        ) : (
          params.value
        ),
    },
    {
      field: "stock",
      headerName: "Total Stock",
      flex: 1,
      renderCell: (params) =>
        editingIndex === params.row.id ? (
          <TextField
            name="stock"
            value={editedItem.stock || ""}
            onChange={handleInputChange}
            fullWidth
          />
        ) : (
          params.value
        ),
    },
    {
      field: "balance_stock",
      headerName: "Balance Stock",
    },
    {
      field: "image_url",
      headerName: "Image",
      flex: 1,
      renderCell: (params) =>
        editingIndex === params.row.id ? (
          <Box>
            <input
              accept="image/*"
              style={{ display: "none" }}
              id={`image-upload-${params.row.id}`}
              type="file"
              onChange={handleImageChange}
            />
            <label htmlFor={`image-upload-${params.row.id}`}>
              <Button variant="contained" component="span">
                Upload
              </Button>
            </label>
            {selectedImage ? (
              <Box mt={1}>
                <img
                  src={URL.createObjectURL(selectedImage)}
                  alt="Selected"
                  style={{ width: "50px", height: "50px" }}
                />
              </Box>
            ) : (
              <img
                src={`https://kamalicrackers.com/ijwyshs_kamali_backend/${editedItem.image_url}`}
                alt="Current"
                style={{ width: "50px", height: "50px" }}
              />
            )}
          </Box>
        ) : (
          <img
            src={
              params.value
                ? `https://kamalicrackers.com/ijwyshs_kamali_backend/${params.value}`
                : DefaultImage
            }
            style={{ width: "50px", height: "50px" }}
          />
        ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <>
          {editingIndex === params.row.id ? (
            <IconButton onClick={handleSaveClick} color="primary">
              <SaveIcon />
            </IconButton>
          ) : (
            <>
              <IconButton
                onClick={() => handleEditClick(params.row.id)}
                color="primary"
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() => handleDeleteClick(params.row.id)}
                color="secondary"
              >
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </>
      ),
    },
  ];

  return (
    <Box>
      <TextField
        variant="outlined"
        placeholder="Search..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        sx={{
          width: "94%",
          marginLeft: "2%",
          marginBottom:"10px",
          // Responsive styles
          "@media (max-width: 768px)": {
            width: "94%",
          marginLeft: "0%",
          marginRight:"3%"
          },
          "@media (max-width: 480px)": {
            width: "94%",
          marginLeft: "0%",
          marginRight:"3%"

          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      <Box
        m="0 20px 0 4%"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": { border: "none" },
          "& .MuiDataGrid-cell": { borderBottom: "none" },
          "& .name-column--cell": { color: colors.greenAccent[300] },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
          "@media (max-width: 768px)": {
            margin: "0 15px 0 0%",
            height:"70vh"
          },
          "@media (max-width: 480px)": {
            margin: "0 15px 0 0%",
            height:"70vh"
          },
        }}
      >
        <DataGrid
          rows={filteredItems}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>

      {/* Dialog component for adding a new category */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Add New Category</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="New Category"
            fullWidth
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddNewCategory} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ItemList;
