import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
// import Topbar from "../scenes/global/Topbar";
import Sidebar from "../scenes/global/Sidebar";
import Dashboard from "../scenes/dashboard";
import Bar from "../scenes/bar";
import { ColorModeContext, useMode } from "../theme";
import Home from "../scenes/customer/Home";
import Add from "../scenes/customer/Add";
import ItemHome from "../scenes/items/ItemHome";
import Item from "../scenes/items/Item";
import OrderSale from "../scenes/ordersale/OrderSale";
import Pos from "../scenes/pos/Pos";
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import PartialPay from "../scenes/pos/PartialPay";
import BillHistory from "../scenes/pos/BillHistory";


const AdminPage = () => {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar isSidebar={isSidebar} />
          <main className="content">
            {/* <Topbar setIsSidebar={setIsSidebar} /> */}
            <Routes>
            <Route path="/" element={<Navigate to="/ijwyshs_admin/dashboard" />} />
            <Route path="dashboard" element={<Dashboard />} />
              <Route path="contacts" element={<ItemHome />} />
              <Route path="ordersale" element={<OrderSale />} />
              <Route path="bar" element={<Bar />} />
              <Route path="customer" element={<Home />} />
              <Route path="customer/add" element={<Add />} />
              <Route path="items" element={<ItemHome />} />
              <Route path="items/add" element={<Item />} />
            
                <Route path="/partial-pay" element={<PartialPay />} />
                <Route path="/bill-history" element={<BillHistory />} />
                <Route path="/pos" element={<Pos />} />
                {/* Add other routes here */}
          
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default AdminPage;
