import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../Shop/CartContext";
import Confetti from "../Home/Confetti";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import "./CartPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import CustomModal from "./CustomModal"; // Import the custom modal
import DefaultImage from "../../../Assets/DefaultImage.png";


const CartPage = () => {
  const { cart, increaseQuantity, decreaseQuantity, removeItem, totalPrice } =
    useContext(CartContext);

  const [showConfetti, setShowConfetti] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [showModal, setShowModal] = useState(false); // State to control modal visibility

  const totalItems = cart.reduce((total, item) => total + item.quantity, 0);
  const navigate = useNavigate();

  const handlePlaceOrder = async (event) => {
    event.preventDefault();

    if (totalPrice < 2000) {
      setAlertMessage(
        "Please add items worth ₹2000 or more to place an order."
      );
      setShowModal(true);
      return;
    }

    if (!phone || phone.length < 10) {
      setPhoneError("Please enter a valid phone number");
      return;
    } else {
      setPhoneError("");
    }

    const formData = new FormData(event.target);
    formData.append("totalItems", totalItems);
    formData.append("subTotal", totalPrice.toFixed(2));
    formData.append(
      "totalDiscounts",
      cart
        .reduce(
          (total, item) =>
            total +
            (item.original_price - item.discounted_price) * item.quantity,
          0
        )
        .toFixed(2)
    );
    formData.append("totalPrice", totalPrice.toFixed(2));
    formData.append("items", JSON.stringify(cart));

    try {
      const response = await fetch("https://kamalicrackers.com/ijwyshs_kamali_backend/place_order.php", {
        method: "POST",
        body: formData,
      });

      const data = await response.json();

      console.log("Response data:", data);

      if (data.status === "success") {
        setAlertMessage("Your order was placed successfully.");
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 1000);
      } else {
        console.log("Error message:", data.message);
        setAlertMessage("Server is currently busy. Please try again later.");
      }
    } catch (error) {
      console.error("Error:", error);
      setAlertMessage("Server is currently busy. Please try again later.");
    }

    setShowModal(true); // Show the modal
  };

  const totalDiscounts = cart.reduce(
    (total, item) =>
      total + (item.original_price - item.discounted_price) * item.quantity,
    0
  );

  return (
    <div className="mv-cart">
      <div className="mv-cartPage1">
      <div className="orderSummary1">
        <div className="summaryHeader1">
          <h3>Order Summary</h3>
          <span className="totalItems1">Items: {totalItems}</span>
        </div>
        <hr className="dummyLine1" />
        <div className="cartItems1">
          {cart.map((item) => (
            <div key={item.id} className="cartItem1">
              <img
                src={
                  item.image_url
                    ? `https://kamalicrackers.com/ijwyshs_kamali_backend/${item.image_url}`
                    : DefaultImage
                }
                alt={item.name}
                className="cartItemImage1"
              />
              <div className="cartItemDetails1">
                <div className="itemInfo1">
                  <p className="itemName1">{item.name}</p>
                  <p className="itemPrice1">
                    ₹{Number(item.discounted_price).toFixed(2)}
                  </p>
                </div>
                <div className="itemControls1">
                  <button
                    onClick={() => decreaseQuantity(item.id)}
                    className="quantityButton1"
                  >
                    -
                  </button>
                  <span className="itemQuantity1">{item.quantity}</span>
                  <button
                    onClick={() => increaseQuantity(item.id)}
                    className="quantityButton1"
                  >
                    +
                  </button>
                </div>
                <button
                  className="removeButton1"
                  onClick={() => removeItem(item.id)}
                >
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    style={{ marginRight: "5px" }}
                  />
                  <div className="cp-remove">Remove</div>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="priceDetails1">
        <h3>Price Details</h3>
        <div className="priceRow1">
          <span>Sub Total</span>
          <span>₹{totalPrice.toFixed(2)}</span>
        </div>
        <div className="priceRow1">
          <span>Discounts</span>
          <span>₹{totalDiscounts.toFixed(2)}</span>
        </div>
        <div className="priceRow1">
          <span>Other Charges</span>
          <span>₹0.00</span>
        </div>
        <hr className="dummyLine1" />
        <div className="priceRow1 totalPay1">
          <span>To Pay</span>
          <span>₹{totalPrice.toFixed(2)}</span>
        </div>
        {totalPrice < 2000 && (
          <div className="minimumOrderMessage1">
            Add items worth ₹2000 or more to place an order.
          </div>
        )}
        <button
          onClick={() => navigate("/delivery-details")}
          className="checkoutButton1"
        >
          Checkout
        </button>
      </div>
    </div>
    <div className="cartPage1">
      <CustomModal
        show={showModal}
        message={alertMessage}
        onClose={() => setShowModal(false)} // Hide the modal on close
      />
      <div className="orderSummary1">
        <div className="summaryHeader1">
          <h3>Order Summary</h3>
          <span className="totalItems1">Items: {totalItems}</span>
        </div>
        <hr className="dummyLine1" />
        <div className="cartItems1">
          {cart.map((item) => (
            <div key={item.id} className="cartItem1">
              <img
                src={
                  item.image_url
                    ? `https://kamalicrackers.com/ijwyshs_kamali_backend/${item.image_url}`
                    : DefaultImage
                }
                alt={item.name}
                className="cartItemImage1"
              />
              <div className="cartItemDetails1">
                <div className="itemInfo1">
                  <p className="itemName1">{item.name}</p>
                  <p className="itemPrice1">
                    ₹{Number(item.discounted_price).toFixed(2)}
                  </p>
                </div>
                <div className="itemControls1">
                  <button
                    onClick={() => decreaseQuantity(item.id)}
                    className="quantityButton1"
                  >
                    -
                  </button>
                  <span className="itemQuantity1">{item.quantity}</span>
                  <button
                    onClick={() => increaseQuantity(item.id)}
                    className="quantityButton1"
                  >
                    +
                  </button>
                </div>
                <button
                  className="removeButton1"
                  onClick={() => removeItem(item.id)}
                >
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    style={{ marginRight: "5px" }}
                  />
                  <div className="cp-remove">Remove</div>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="deliveryDetails1">
        <h3>Delivery Address</h3>
        <form onSubmit={handlePlaceOrder}>
          <input
            type="text"
            name="name"
            placeholder="Name *"
            required
            style={{ marginTop: "15px" }}
          />
          <PhoneInput
            country={"in"}
            value={phone}
            onChange={(value) => {
              setPhone(value);
              if (value.length >= 10) {
                setPhoneError("");
              }
            }}
            inputProps={{
              name: "phone",
              required: true,
              autoFocus: true,
            }}
          />
          {phoneError && <p className="errorMessage1">{phoneError}</p>}
          <textarea name="address" placeholder="Address" required></textarea>
          <div className="priceDetails1">
            <h3>Price Details</h3>
            <div className="priceRow1">
              <span>Sub Total</span>
              <span>₹{totalPrice.toFixed(2)}</span>
            </div>
            <div className="priceRow1">
              <span>Discounts</span>
              <span>₹{totalDiscounts.toFixed(2)}</span>
            </div>
            <div className="priceRow1">
              <span>Other Charges</span>
              <span>₹0.00</span>
            </div>
            <hr className="dummyLine1" />
            <div className="priceRow1 totalPay1">
              <span>To Pay</span>
              <span>₹{totalPrice.toFixed(2)}</span>
            </div>
            {totalPrice < 2000 && (
              <div className="minimumOrderMessage1">
                Add items worth ₹2000 or more to place an order.
              </div>
            )}
            <button type="submit" className="placeOrderButton1">
              Place Order
            </button>
           
          </div>
        </form>
      </div>
      <Confetti run={showConfetti} />
    </div>
    </div>
  );
};

export default CartPage;
