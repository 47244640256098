import React from 'react';
import Slider from 'react-slick';
import './Carousel.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import FireworksEffect from './FireworksEffect'; // Import the FireworksEffect component

import image1 from '../../../Assets/Crackers.png';
import image2 from '../../../Assets/Home.jpg';
import image3 from '../../../Assets/fire-banner.png';
import image4 from '../../../Assets/BANNER-2.jpg';
import image5 from '../../../Assets/BANNER-3.jpg';

const images = [
  image2,
  image3,
  image4,
  image5,
];

function Carousel() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <div className="cl-carousel">
      <FireworksEffect /> {/* Add the FireworksEffect component */}
      <div className='cl-home-image'>
        <img src={image1} alt="Home" />
      </div>
      <div className='cl-slider-part'>
        <Slider {...settings}>
          {images.map((img, index) => (
            <div key={index}>
              <img src={img} alt={`Slide ${index + 1}`} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Carousel;
