import React, { useEffect, useState } from "react";
import "./HeaderSale.css";

const HeaderSale = ({ tabs, activeTab, onTabClick }) => {
  const [localActiveTab, setLocalActiveTab] = useState(activeTab);

  useEffect(() => {
    setLocalActiveTab(activeTab);
  }, [activeTab]);

  const handleTabClick = (tab) => {
    setLocalActiveTab(tab);
    onTabClick(tab);
  };

  return (
    <div className="OS-hd-nav-bar">
      {tabs.map((tab) => (
        <div
          key={tab}
          className={`OS-hd-nav-item ${localActiveTab === tab ? "active" : ""}`}
          onClick={() => handleTabClick(tab)}
        >
          {tab}
        </div>
      ))}
    </div>
  );
};

export default HeaderSale;
