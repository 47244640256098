import React, { useEffect, useState } from "react";
import "./OnlineOrders.css";
import DefaultImage from "../../Assets/DefaultImage.png";

const OnlineOrders = ({ onConvertToSale, updatedOrderItems, setActiveTab }) => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("all");
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch("https://kamalicrackers.com/ijwyshs_kamali_backend/get_orders.php");
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log("Fetched Data: ", data);

        if (data.status === "success") {
          const sortedOrders = data.orders
            .map(order => ({
              ...order,
              // Calculate total items based on items array length
              total_items: Array.isArray(order.items) ? order.items.length : 0,
            }))
            .sort((a, b) => b.id - a.id); // Sort orders in descending order based on order id
          setOrders(sortedOrders);
          if (sortedOrders.length > 0) {
            setSelectedOrder(sortedOrders[0]);
          }
        } else {
          console.error("Error fetching orders:", data.message);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, []);

  // Handle updating orders with updated order items
  useEffect(() => {
    if (updatedOrderItems && selectedOrder) {
      const updatedOrders = orders.map(order => {
        if (order.id === selectedOrder.id) {
          return {
            ...order,
            items: updatedOrderItems,
          };
        }
        return order;
      });
      setOrders(updatedOrders);
    }
  }, [updatedOrderItems, selectedOrder, orders]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleConvertToSale = () => {
    if (selectedOrder) {
      console.log("Selected order:", selectedOrder);
      onConvertToSale(selectedOrder);
      setActiveTab("SALE ORDERS");
    }
  };

  const handleCancelOrder = async () => {
    if (selectedOrder) {
      try {
        const response = await fetch(
          "https://kamalicrackers.com/ijwyshs_kamali_backend/update_order_status.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              orderId: selectedOrder.id,
              newStatus: "cancelled",
            }),
          }
        );

        const data = await response.json();
        if (data.status === "success") {
          const updatedOrders = orders.map(order =>
            order.id === selectedOrder.id
              ? { ...order, status: "cancelled" }
              : order
          );
          setOrders(updatedOrders);
          setShowPopup(true);
        } else {
          console.error("Error updating order status:", data.message);
        }
      } catch (error) {
        console.error("Error updating order status:", error);
      }
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const calculateSubtotalAndTotal = (items) => {
    const subtotal = items.reduce(
      (acc, item) => acc + item.item_quantity * item.item_discounted_price,
      0
    );
    const totalDiscounts = items.reduce(
      (acc, item) =>
        acc +
        (item.item_original_price - item.item_discounted_price) *
          item.item_quantity,
      0
    );
    const total = subtotal - totalDiscounts;
    return { subtotal, total, totalDiscounts };
  };

  const handleFilterClick = (newFilter) => {
    setFilter(newFilter);
  };

  const filteredOrders = orders.filter(order => {
    const matchesSearch =
      order.id.toString().includes(searchTerm) ||
      order.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesFilter = filter === "all" || order.status === filter;
    return matchesSearch && matchesFilter;
  });

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="on-order-sale-container">
      {showPopup && (
        <div className="on-popup">
          <div className="on-popup-content">
            <p>Order cancelled successfully</p>
            <button onClick={closePopup}>OK</button>
          </div>
        </div>
      )}
      <div className="on-sidebar">
        <input
          type="text"
          placeholder="Search Order"
          className="on-search-order"
          value={searchTerm}
          onChange={handleSearch}
        />
        <div className="on-filter-buttons">
          <button
            className={`on-filter-button ${filter === "all" ? "selected" : ""}`}
            onClick={() => handleFilterClick("all")}
          >
            All
          </button>
          <button
            className={`on-filter-button ${
              filter === "open" ? "selected" : ""
            }`}
            onClick={() => handleFilterClick("open")}
          >
            Open
          </button>
          <button
            className={`on-filter-button ${
              filter === "cancelled" ? "selected" : ""
            }`}
            onClick={() => handleFilterClick("cancelled")}
          >
            Cancelled
          </button>
          <button
            className={`on-filter-button ${
              filter === "completed" ? "selected" : ""
            }`}
            onClick={() => handleFilterClick("completed")}
          >
            Completed
          </button>
        </div>
        <div className="on-order-list">
          {filteredOrders.map(order => (
            <div
              key={order.id}
              className="on-order-item"
              style={{
                backgroundColor:
                  selectedOrder && selectedOrder.id === order.id
                    ? "#e0f7fa" // Light cyan for selected orders
                    : "transparent",
              }}
              onClick={() => setSelectedOrder(order)}
            >
              <div className="on-order-info-left">
                <div className="on-order-number">
                  Order #{order.id}{" "}
                  {order.status === "open" && (
                    <span
                      className="on-open-tag"
                      style={{ backgroundColor: "rgb(255, 255, 130)" }}
                    >
                      Open
                    </span>
                  )}{" "}
                  {order.status === "cancelled" && (
                    <span
                      className="on-cancelled-tag"
                      style={{ backgroundColor: "rgb(255, 104, 104)" }}
                    >
                      Cancelled
                    </span>
                  )}{" "}
                  {order.status === "completed" && (
                    <span
                      className="on-completed-tag"
                      style={{ backgroundColor: "rgb(65, 225, 102)" }}
                    >
                      Completed
                    </span>
                  )}
                </div>
                <div className="on-order-customer">{order.name}</div>
                <div className="on-order-items">{order.total_items} items</div>
              </div>
              <div className="on-order-info-right">
                <div className="on-order-date">
                  {formatDate(order.order_date)}
                </div>
                <div className="on-order-total">
                  ₹{" "}
                  {calculateSubtotalAndTotal(order.items).subtotal}
                </div>{" "}
                {/* Display calculated subtotal */}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="on-order-details">
        {selectedOrder && (
          <>
            <div className="on-order-header">
              <div>
                Order placed on {formatDate(selectedOrder.order_date)}
              </div>
              <div>Order ID: {selectedOrder.id}</div>
            </div>
            <div className="on-summary-header">
              <span>Order #{selectedOrder.id}</span>
              <span>{selectedOrder.total_items} items</span>
            </div>
            <br />
            <div className="on-order-info">
              <div className="on-order-info-item">
                <span className="on-order-info-label">NAME</span>
                <span className="on-order-info-value">
                  {selectedOrder.name}
                </span>
              </div>
              <div className="on-order-info-item">
                <span className="on-order-info-label">ADDRESS</span>
                <span className="on-order-info-value">
                  {selectedOrder.address}
                </span>
              </div>

              <div className="on-order-info-item">
                <span className="on-order-info-label">CONTACT NO</span>
                <span className="on-order-info-value">
                  {selectedOrder.phone}
                </span>
              </div>
            </div>
            <hr className="order-line"/>
            <br />
            <div className="on-order-summary-container">
              <div className="on-order-summary">
                <div className="on-summary-header">
                  <span>Order Summary</span>
                  <span>{selectedOrder.total_items} items</span>
                </div>
                {Array.isArray(selectedOrder.items) &&
                selectedOrder.items.length > 0 ? (
                  selectedOrder.items.map((item, index) => (
                    <div key={index} className="on-summary-item">
                      <div className="on-summary-item-image">
                        <img
                          src={`https://kamalicrackers.com/ijwyshs_kamali_backend/${item.item_image_url}`}
                          alt="Item"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = DefaultImage;
                          }}
                        />
                      </div>
                      <div className="on-summary-item-description">
                        <div>{item.item_name}</div>
                        <div className="item_category">{item.item_category}</div>
                        <div>
                          {item.item_quantity} X{" "}
                          <b>₹{item.item_discounted_price}</b>
                        </div>
                      </div>
                      <div className="on-summary-item-details">
                        <div>
                          ₹{item.item_quantity * item.item_discounted_price}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>No items found for this order.</div>
                )}
              </div>
              <div className="on-price-details">
                <span className="on-summary-header">Price Details</span>
                <div className="on-price-item">
                  <span>Sub Total:</span>
                  <span>
                    ₹ {calculateSubtotalAndTotal(selectedOrder.items).subtotal}
                  </span>
                </div>
                <div className="on-price-item">
                  <span>Discounts:</span>
                  <span>
                    ₹{" "}
                    {
                      calculateSubtotalAndTotal(selectedOrder.items)
                        .totalDiscounts
                    }
                  </span>
                </div>
                <br />
                <hr className="order-line"/>
                <br />
                <div className="on-price-item total">
                  <span>Total:</span>
                  <span>
                    ₹ {calculateSubtotalAndTotal(selectedOrder.items).subtotal}
                  </span>
                </div>
              </div>
            </div>
            <div className="on-order-actions">
              <button className="on-convert-sale" onClick={handleConvertToSale}>
                Convert to Sale
              </button>
              <button
                className="on-deliver-order"
                onClick={handleCancelOrder}
                disabled={
                  selectedOrder.status === "cancelled" ||
                  selectedOrder.status === "completed"
                }
              >
                Cancel Order
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default OnlineOrders;
