import React from 'react';
import './Icons.css';
import dialerIcon from '../../../Assets/Dialer.png'; // Replace with actual path to dialer icon image
import whatsappIcon from '../../../Assets/Whatsapp.webp'; // Replace with actual path to WhatsApp icon image
import locationIcon from '../../../Assets/Map.png'; // Replace with actual path to location icon image

const ContactIcons = () => {
  const handleCall = () => {
    window.location.href = 'tel: 9005900858';
  };

  const handleWhatsApp = () => {
    const message = encodeURIComponent("'https://kamalicrackers.com/' 'Hi, I like to celebrate this Diwali with Kamali Crackers and I have something to clarify with you! Reply me..'");
    window.open(`https://wa.me/919005900858/?text=${message}`, '_blank');
  };

  const handleLocation = () => {
    window.open('https://maps.google.com/?cid=9272412426289190011&entry=gps', '_blank');
  };

  return (
    <div className="Ic-contact-icons">
      <div className="Ic-Dial-icon" onClick={handleCall}>
        <img src={dialerIcon} alt="Dialer Icon" />
      </div>
      <div className="Ic-Whatsapp-icon" onClick={handleWhatsApp}>
        <img src={whatsappIcon} alt="WhatsApp Icon" />
      </div>
      <div className="Ic-Location-icon" onClick={handleLocation}>
        <img src={locationIcon} alt="Location Icon" />
      </div>
    </div>
  );
};

export default ContactIcons;
