import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';

const AdminLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = () => {
    if (username === 'Kamali' && password === 'Kamali@203') {
      navigate('/admin'); // Assuming you have an admin route
    } else {
      setError('Incorrect username or password');
    }
  };

  const handleBackToLogin = () => {
    console.log("Navigating back to login");
    navigate('/'); // This should be the correct path for your LoginPage
  };

  return (
    <div className="LP-app-container">
      {/* <button onClick={handleBackToLogin} className="LP-back-button">Back to Login</button> */}
      <div className="LP-auth-box">
        <h2 className="LP-auth-title">Admin Login</h2>
        <form className="LP-auth-form" onSubmit={(e) => e.preventDefault()}>
          <input
            type="text"
            className="LP-auth-input"
            placeholder="Admin Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            className="LP-auth-input"
            placeholder="Admin Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button className="LP-auth-button" onClick={handleLogin}>Login</button>
          {error && <p className="LP-auth-error">{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
