import React, { useState } from "react";
import "./Header.css";

const Header = ({ tabs, onTabClick }) => {
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    onTabClick(tab);
  };

  return (
    <div className="hd-nav-bar">
      {tabs.map((tab) => (
        <div
          key={tab}
          className={`hd-nav-item ${activeTab === tab ? "active" : ""}`}
          onClick={() => handleTabClick(tab)}
        >
          {tab}
        </div>
      ))}
    </div>
  );
};

export default Header;
