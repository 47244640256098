import React, { useState } from "react";
import HeaderSale from "./HeaderSale";
import OnlineOrders from "../ordersale/OnlineOrders";
import SaleOrders from "./SaleOrders";
import { Box } from "@mui/material";

const OrderSale = () => {
  const tabs = ["ONLINE ORDERS", "SALE ORDERS"];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const handleTabClick = (tab) => {
    console.log("Changing active tab to:", tab);
    setActiveTab(tab);
  };

  const handleConvertToSale = (order) => {
    console.log("Converting to sale:", order);
    setSelectedOrder(order);
    setActiveTab("SALE ORDERS");
  };

  const renderTabContent = () => {
    console.log("Rendering content for tab:", activeTab);
    switch (activeTab) {
      case "ONLINE ORDERS":
        return (
          <OnlineOrders
            onConvertToSale={handleConvertToSale}
            setActiveTab={setActiveTab}
          />
        );
      case "SALE ORDERS":
        return <SaleOrders order={selectedOrder} />;
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        ml: { xs: 3, sm: 3, md: 1, lg: 1 }, // Adjust margins for different breakpoints
        p: 2 // Optional padding
      }}
    >
      <HeaderSale tabs={tabs} activeTab={activeTab} onTabClick={handleTabClick} />
      {renderTabContent()}
    </Box>
  );
};

export default OrderSale;
