import React, { useState, useContext, useEffect } from "react";
import "./QuickBuy.css";
import { CartContext } from "../Shop/CartContext";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import DefaultImage from "../../../Assets/DefaultImage.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";

const QuickBuy = () => {
  const { cart, addToCart, increaseQuantity, decreaseQuantity } =
    useContext(CartContext);
  const [productsData, setProductsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for controlling the pop-up
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    fetch("https://kamalicrackers.com/ijwyshs_kamali_backend/fetch_items.php")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setProductsData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
        setError(error);
        setLoading(false);
      });
  }, []);

  const handleAddToCart = (product) => {
    addToCart(product);
  };

  const handleIncreaseQuantity = (productId) => {
    increaseQuantity(productId);
  };

  const handleDecreaseQuantity = (productId) => {
    decreaseQuantity(productId);
  };

  const calculateTotal = () => {
    let totalQuantity = 0;
    let totalAmount = 0;
    let totalSavings = 0;

    cart.forEach((item) => {
      totalQuantity += item.quantity;
      totalAmount += item.quantity * item.discounted_price;
      totalSavings +=
        item.quantity * (item.original_price - item.discounted_price);
    });

    return { totalQuantity, totalAmount, totalSavings };
  };

  const totals = calculateTotal();

  const renderProductsByCategory = (category) => {
    return productsData
      .filter((product) => product.category === category)
      .map((product) => (
        <tr key={product.id} className="qb-product-row">
          <td className="qb-item-image">
            <img
              src={
                product.image_url
                  ? `https://kamalicrackers.com/ijwyshs_kamali_backend/${product.image_url}`
                  : DefaultImage
              }
              alt={product.name}
              className="qb-product-image"
            />
          </td>
          <td className="qb-product-name-m">
            {product.name}
            <br></br>
            <div className="qb-price-m-display">
              <span className="qb-discounted-price-m">
                ₹{product.discounted_price}
              </span>
              <br />
              <span className="qb-original-price-m">
                ₹{product.original_price}
              </span>
            </div>
          </td>
          <td className="qb-price">
            <div className="qb-price">
              <span className="qb-discounted-price">
                ₹{product.discounted_price}
              </span>
              <br />
              <span className="qb-original-price">
                ₹{product.original_price}
              </span>
            </div>
          </td>
          <td className="qb-qty-m">
            {cart.find((item) => item.id === product.id) ? (
              <div className="qb-quantity-controls">
                <button
                  className="qb-quantity-button"
                  onClick={() => handleDecreaseQuantity(product.id)}
                >
                  -
                </button>
                <span className="qb-quantity">
                  {cart.find((item) => item.id === product.id).quantity}
                </span>
                <button
                  className="qb-quantity-button"
                  onClick={() => handleIncreaseQuantity(product.id)}
                >
                  +
                </button>
              </div>
            ) : (
              <button
                className="qb-add-to-cart-button"
                onClick={() => handleAddToCart(product)}
              >
                Add
              </button>
            )}
          </td>
          <td className="qb-total-m">
            ₹
            {(cart.find((item) => item.id === product.id)?.quantity || 0) *
              product.discounted_price}
          </td>
        </tr>
      ));
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const categories = [
    ...new Set(productsData.map((product) => product.category)),
  ];

  const handleProceedToCart = () => {
    if (cart.length === 0) {
      setIsModalOpen(true); 
    } else {
      navigate("/cart"); // Navigate to CartPage.jsx
    }
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  return (
    <div className="qb-container">
      <Helmet>
        <title>Kamali Crackers - Quick Buy</title>
        {/* Meta Description */}
        <meta
          name="description"
          content="Quickly add fireworks and crackers to your cart with Kamali Crackers' Quick Buy feature. Browse categories, add to cart, and proceed to checkout effortlessly."
        />

        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="Kamali crackers, buy crackers online Sivakasi, online crackers Sivakasi, Sivakasi fireworks, Diwali crackers online Sivakasi, wholesale crackers Sivakasi, Sivakasi firecrackers, order crackers online Sivakasi, best crackers Sivakasi, cheap crackers Sivakasi, crackers delivery Sivakasi, Kamali crackers shop online, festival fireworks Sivakasi, Kamali crackers wholesale, crackers online Tamil Nadu, fireworks online Sivakasi, Kamali crackers for Diwali, bulk crackers Sivakasi, discount crackers Sivakasi, crackers wholesale Sivakasi, Sivakasi fireworks for festivals, buy fireworks online Sivakasi, Kamali crackers sale, Sivakasi gift box crackers, Sivakasi fancy crackers"
        />

        {/* Author */}
        <meta name="Sankarapandi" content="Kamali Crackers" />

        {/* Open Graph Meta Tags for Social Media */}
        <meta property="og:title" content="Quick Buy - Kamali Crackers" />
        <meta
          property="og:description"
          content="Quickly add fireworks and crackers to your cart with Kamali Crackers' Quick Buy feature. Browse categories, add to cart, and proceed to checkout effortlessly."
        />
        <meta property="og:image" content="/brand-image.jpg" />
        <meta
          property="og:url"
          content="https://www.kamalicrackers.com/quickBuy"
        />
        <meta property="og:type" content="website" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Quick Buy - Kamali Crackers" />
        <meta
          name="twitter:description"
          content="Quickly add fireworks and crackers to your cart with Kamali Crackers' Quick Buy feature. Browse categories, add to cart, and proceed to checkout effortlessly."
        />
        <meta name="twitter:image" content="/brand-image.jpg" />
      </Helmet>

      <div className="qb-summary">
        <div className="qb-summary-item" style={{ backgroundColor: "#ff9203" }}>
          <span className="qb-summary-title">No.of Products</span>
          <br className="qb-break"></br>
          <hr className="qb-border"></hr>
          <span className="qb-summary-value">{cart.length}</span>
        </div>
        <div className="qb-summary-item" style={{ backgroundColor: "#004394" }}>
          <span className="qb-summary-title">No.of Items</span>
          <br className="qb-break"></br>
          <hr className="qb-border"></hr>
          <span className="qb-summary-value">{totals.totalQuantity}</span>
        </div>
        <div className="qb-summary-item" style={{ backgroundColor: "#00988d" }}>
          <span className="qb-summary-title">Total Amount</span>
          <br className="qb-break"></br>
          <hr className="qb-border"></hr>
          <span className="qb-summary-value">
            ₹{totals.totalAmount.toFixed(2)}
          </span>
        </div>
        <div
          className="qb-summary-item-u"
          style={{ backgroundColor: "#e50b6f" }}
        >
          <span className="qb-summary-title-u">YOU SAVE</span>
          <br />
          <hr className="qb-border"></hr>
          <span className="qb-summary-value-u">
            ₹{totals.totalSavings.toFixed(2)}
          </span>
        </div>
      </div>
      <table className="qb-product-table">
        <thead>
          <tr className="qb-table-header">
            <th className="qb-image-column">IMAGE</th>
            <th className="qb-product-name-m">PRODUCT NAME</th>
            <th className="qb-price-display">PRICE</th>
            <th className="qb-qty-m">QTY</th>
            <th className="qb-total-m">SUB-TOTAL</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category) => (
            <React.Fragment key={category}>
              <tr>
                <td
                  colSpan="5"
                  className="qb-category-title"
                  style={{ textAlign: "left" }}
                >
                  {category}
                </td>
              </tr>
              {renderProductsByCategory(category)}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <button className="qb-proceed-button" onClick={handleProceedToCart}>
        Go
        <FontAwesomeIcon icon={faCartShopping} />
      </button>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Cart is Empty"
        ariaHideApp={false} // Required for accessibility
        className="modal" // Add className for styling
      >
        <h2>Your cart is empty</h2>
        <p>Please add at least one product to proceed.</p>
        <button onClick={closeModal}>OK</button>
      </Modal>

    </div>
  );
};

export default QuickBuy;
