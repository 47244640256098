import React from 'react';
import { useNavigate } from 'react-router-dom';
import Card from './Card';
import './CategoriesGrid.css';
import sparklers from '../../../Assets/Sparklers.jpg';
import flowerpots from '../../../Assets/Flower-pots.jpg';
import kidscrackers from '../../../Assets/Kids Crackers.jpg';
import rockets from '../../../Assets/Rockets.avif';
import megaaerialfancy from '../../../Assets/Fancy.png';
import multicolourshots from '../../../Assets/MultiShots.jpg';
import onesoundcrackers from '../../../Assets/Sound-crackers.png';
import groundchakkaras from '../../../Assets/Chakkars.jpg';
import colorfountain from '../../../Assets/Color fountain.jpg';
import atombomb from '../../../Assets/Atom Bomb.png';
import FamilyPacks from '../../../Assets/Family Packs.jpg';
import giftbox from '../../../Assets/Gift box.png';
import NewArrivals from '../../../Assets/Others.jpg'

const categories = [
  { image: sparklers, title: 'Sparklers' },
  { image: flowerpots, title: 'Flower Pots' },
  { image: kidscrackers, title: 'Kids Collection' },
  { image: rockets, title: 'Rockets' },
  { image: NewArrivals, title: 'New Arrivals' },
  { image: multicolourshots, title: 'Multi Shots' },
  { image: onesoundcrackers, title: 'One Sound Crackers' },
  { image: groundchakkaras, title: 'Ground Chakkars' },
  { image: megaaerialfancy, title: 'Fancy Aerial Shots' },
  { image: atombomb, title: 'Bombs' },
  { image: FamilyPacks, title: 'Family Packs' },
  { image: giftbox, title: 'Gift Boxes' },
];

function CategoriesGrid() {
  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    navigate('/shop', { state: { selectedCategory: category } });
  }; 

  return (
    <div className='category-view'>
    <div className="categories-grid">
      {categories.map((category, index) => (
        <Card
          key={index}
          image={category.image}
          title={category.title}
          onClick={() => handleCategoryClick(category.title)}
        />
      ))}
    </div>
    <div className="categories-grid-mv">
      {categories.map((category, index) => (
        <Card
          key={index}
          image={category.image}
          title={category.title}
          onClick={() => handleCategoryClick(category.title)}
        />
      ))}
    </div>
    </div>
    
  );

}
export default CategoriesGrid;
