import React from 'react';
import Crackers_Made from './Crackers_Made';

import './ContactImage.css';

function ContactImage() {
  return (
    <div className="c-img-image-container">
      <br></br>
      <Crackers_Made />
     
      <p>© 2024 www.kamalicrackers.com. All rights reserved</p>

    </div>
  );
}

export default ContactImage;
