import React, { useEffect, useState } from "react";
import jsPDF from "jspdf";
import "./DejaVuSans-normal.js"; // Import the custom font

import autoTable from "jspdf-autotable";
import {
  Box,
  ButtonGroup,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./SaleOrders.css";
import BillLogo from "../../Assets/Bill_Logo.png";
import Welcome from "../../Assets/welcome.png";

const SaleOrders = ({ order }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [billLogoBase64, setBillLogoBase64] = useState("");
  const [orderItems, setOrderItems] = useState([]);
  const [error, setError] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editedItem, setEditedItem] = useState({});
  const [orderStatus, setOrderStatus] = useState(""); // Track order status
  const [anchorEl, setAnchorEl] = useState(null); // Anchor element for the dropdown menu

  useEffect(() => {
    if (order && order.id) {
      fetchOrderItems(order.id);
      // Assuming order.status is available in your order object
      if (order.status) {
        setOrderStatus(order.status);
      }
    }
  }, [order]);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(BillLogo);
        const blob = await response.blob();
        const reader = new FileReader();
        reader.onloadend = () => {
          setBillLogoBase64(reader.result);
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error("Error fetching the image:", error);
      }
    };

    fetchImage();
  }, []);
  const fetchOrderItems = async (orderId) => {
    try {
      const response = await fetch(
        `https://kamalicrackers.com/ijwyshs_kamali_backend/get_order_items.php?order_id=${orderId}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setOrderItems(data.items || []);
    } catch (error) {
      setError("Error fetching order items: " + error.message);
      console.error("Error fetching order items:", error);
    }
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
    setEditedItem(orderItems[index]);
  };

  const handleSave = async (index) => {
    const updatedItems = [...orderItems];
    updatedItems[index] = editedItem;
    setOrderItems(updatedItems);
    setEditingIndex(null);

    try {
      const response = await fetch(
        "https://kamalicrackers.com/ijwyshs_kamali_backend/update_order_item.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(editedItem),
        }
      );

      const result = await response.json();

      if (result.status !== "success") {
        throw new Error(result.message);
      }
    } catch (error) {
      setError("Error updating order item: " + error.message);
      console.error("Error updating order item:", error);
    }
  };

  const handleDelete = async (index) => {
    const itemId = orderItems[index].id;

    const updatedItems = orderItems.filter((_, i) => i !== index);
    setOrderItems(updatedItems);

    try {
      const response = await fetch(
        "https://kamalicrackers.com/ijwyshs_kamali_backend/delete_order_item.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ item_id: itemId }),
        }
      );

      const result = await response.json();

      if (result.status !== "success") {
        throw new Error(result.message);
      }
    } catch (error) {
      setError("Error deleting order item: " + error.message);
      console.error("Error deleting order item:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedItem({ ...editedItem, [name]: value });
  };

  const calculateTotalAmount = () => {
    return orderItems.reduce(
      (total, item) =>
        total + item.item_quantity * parseFloat(item.item_discounted_price),
      0
    );
  };

  const handleSaveOrder = async () => {
    const orderItemsWithAmount = orderItems.map((item) => ({
      ...item,
      amount: item.item_quantity * parseFloat(item.item_discounted_price),
    }));

    const totalAmount = orderItemsWithAmount.reduce(
      (total, item) => total + item.amount,
      0
    );

    const orderData = {
      customerDetails: {
        name: order.name,
        address: order.address,
        phone: order.phone,
      },
      orderDetails: {
        id: order.id,
        order_date: new Date(order.order_date).toISOString(),
        totalAmount: totalAmount, // Add total amount
      },
      orderItems: orderItemsWithAmount, // Add order items with amount
    };

    try {
      const response = await fetch(
        "https://kamalicrackers.com/ijwyshs_kamali_backend/save_order_details.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(orderData),
        }
      );

      const result = await response.json();

      if (result.status === "success") {
        alert("Order details saved successfully");
        // You can add printing functionality here if needed
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      setError("Error saving order details: " + error.message);
      console.error("Error saving order details:", error);
    }
  };

  const handleDeliverOrder = async () => {
    if (order && order.id && orderStatus !== "completed") {
      try {
        const response = await fetch(
          "https://kamalicrackers.com/ijwyshs_kamali_backend/update_order_status.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ orderId: order.id, newStatus: "completed" }),
          }
        );

        const result = await response.json();

        if (result.status === "success") {
          setOrderStatus("completed"); // Update local state
        } else {
          throw new Error(result.message);
        }
      } catch (error) {
        setError("Error delivering order: " + error.message);
        console.error("Error delivering order:", error);
      }
    }
  };

  const handleDropdownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handlePrint = () => {
    const printWindow = window.open("", "", "width=1000,height=800");
    const orderDate = new Date(order.order_date).toLocaleDateString();
    const totalAmount = calculateTotalAmount();
    const orderItemsHtml = orderItems
      .map((item, index) => {
        const discountedPrice = parseFloat(item.item_discounted_price);
        const amount = parseFloat(item.item_quantity) * discountedPrice;

        return `
          <tr>
            <td style="border: 1px solid #ddd; padding: 8px; text-align: center;">${
              index + 1
            }</td>
            <td style="border: 1px solid #ddd; padding: 8px;">${
              item.item_name
            }</td>
            <td style="border: 1px solid #ddd; padding: 8px; text-align: center;">${
              item.item_quantity
            }</td>
            <td style="border: 1px solid #ddd; padding: 8px; text-align: right;">₹${discountedPrice.toFixed(
              2
            )}</td>
            <td style="border: 1px solid #ddd; padding: 8px; text-align: right;">₹${amount.toFixed(
              2
            )}</td>
          </tr>
        `;
      })
      .join("");

    const printContent = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Invoice</title>
          <style>
              body {
                  font-family: Arial, sans-serif;
                  background-color: #f8f9fa;
                  padding: 20px;
              }
              .invoice-container {
                  max-width: 800px;
                  margin: auto;
                  background-color: #fff;
                  padding: 20px;
                  border: 1px solid #ddd;
                  border-radius: 4px;
                  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
              }
              .invoice-header {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  margin-bottom: 20px;
              }
              .invoice-header img {
                  height: 70px;
                  width: 70px;
                  margin-right: 20px;
                  margin-bottom: 55px;
                  margin-top: 30px;
                
              }
              .invoice-header div {
                  text-align: left;
              }
              .invoice-details {
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 20px;
              }
              .invoice-details > div {
                  width: 48%;
              }
              .invoice-footer {
                  margin-bottom: 20px;
              }
              .invoice-footer h4 {
                  margin: 0 0 10px 0;
              }
              table {
                  width: 100%;
                  border-collapse: collapse;
                  margin-bottom: 20px;
              }
              table, th, td {
                  border: 1px solid #ddd;
                  padding: 8px;
              }
              th {
                  background-color: #f2f2f2;
                  text-align: left;
              }
              .text-right {
                  text-align: right;
              }
          </style>
      </head>
      <body>
          <div class="invoice-container">
              <div class="invoice-header">
                  <img src="${billLogoBase64}" alt="Logo">
                  <div>
                      <h2>Kamali Crackers</h2>
                      <p>1/740, RR nagar, Viudhunagar, Tamilnadu, India - 626204<br>Phone no.:9005900858</p>
                  </div>
              </div>
              <div class="invoice-details">
                  <div>
                      <h4>Bill To</h4>
                      <p>Name: ${order.name}</p>
                      <p>Phone: ${order.phone}</p>
                      <p>Address: ${order.address}</p>
                  </div>
                  <div class="text-right">
                      <h3>Tax Invoice</h3>
                      <p>Invoice No.: ${order.id}</p>
                      <p>Date: ${orderDate}</p>
                  </div>
              </div>
              <table>
                  <thead>
                      <tr>
                          <th>#</th>
                          <th>Item Name</th>
                          <th>Quantity</th>
                          <th>Price/Unit</th>
                          <th>Amount</th>
                      </tr>
                  </thead>
                  <tbody>
                      ${orderItemsHtml}
                      <tr>
                          <td colspan="3"></td>
                          <td class="text-right"><strong>Total</strong></td>
                          <td style="text-align: right;"><strong>₹${totalAmount.toFixed(
                            2
                          )}</strong></td>
                      </tr>
                  </tbody>
              </table>
              <div class="invoice-footer">
                  <div class="text-right">
                      <p>For: Kamali Crackers Team</p>
                      <p style="margin-top: 50px;">Authorized Signatory</p>
                  </div>
              </div>
          </div>
      </body>
      </html>`;

    printWindow.document.write(printContent);
    printWindow.document.close();
    printWindow.print();

    // Close the print window after printing
    printWindow.close();
  };

const handleGeneratePDF = async () => {
  const doc = new jsPDF();
  doc.setFont('DejaVuSans');

  // Add logo
  doc.addImage(BillLogo, 'PNG', 15, 21, 23, 23);

  // Shop Name
  doc.setFontSize(20);
  doc.text('Kamali Crackers', 43, 30);

  // Store Details
  doc.setFontSize(12);
  const storeDetails = `1/740, RR nagar, Viudhunagar, Tamilnadu, India - 626204 \nPhone no.:  9005900858`;
  doc.text(storeDetails, 43, 38);

  // Add Customer Details
  doc.setFontSize(13);
  doc.text('Bill To:', 20, 60);
  doc.setFontSize(10);
  doc.text(`Name: ${order.name}`, 20, 70);
  doc.text(`Phone: ${order.phone}`, 20, 77);
  doc.text(`Address: ${order.address}`, 20, 84);

  // Add Order Details
  doc.setFontSize(13);
  doc.text('Tax Invoice:', 150, 60);
  doc.setFontSize(10);
  doc.text(`Invoice No.: ${order.id}`, 150, 70);
  doc.text(`Date: ${new Date(order.order_date).toLocaleDateString()}`, 150, 77);

  // Add table
  autoTable(doc, {
    startY: 100,
    head: [['#', 'Item Name', 'Quantity', 'Price/Unit', 'Amount']],
    body: [
      ...orderItems.map((item, index) => [
        { content: index + 1, styles: { halign: 'left' } },
        item.item_name,
        { content: item.item_quantity, styles: { halign: 'center' } },
        { content: `${parseFloat(item.item_discounted_price).toFixed(2)}`, styles: { halign: 'center' } },
        { content: `${(item.item_quantity * parseFloat(item.item_discounted_price)).toFixed(2)}`, styles: { halign: 'center' } },
      ]),
      [
        { content: '', colSpan: 3, styles: { halign: 'center', fontStyle: 'bold' } },
        { content: 'Total', styles: { halign: 'center', fontStyle: 'bold' } },
        { content: `Rs.${calculateTotalAmount().toFixed(2)}`, styles: { halign: 'center', fontStyle: 'bold' } }
      ]
    ],
    styles: {
      cellPadding: 4, // Reduced padding to reduce row height
      fontSize: 10,
      overflow: 'linebreak',
      halign: 'left',
      valign: 'middle',
      lineColor: [221, 221, 221],
      lineWidth: 0.5,
      minCellHeight: 10 // Minimum height for cells
    },
    headStyles: {
      fillColor: [242, 242, 242],
      textColor: [0, 0, 0],
      fontSize: 10,
      halign: 'center',
    },
    alternateRowStyles: {
      fillColor: [250, 250, 250],
    },
    margin: { top: 10, left: 15, right: 15, bottom: 10 }, // Added margin to left and right sides
  });

  // Add thank you message with images on left and right
  const imageWidth = 20; // Adjust the width as needed
  const imageHeight = 10; // Adjust the height as needed
  const startY = doc.lastAutoTable.finalY + 20;

  // Position of the thank you message
  const messageY = startY + (imageHeight / 2) + 2; // Vertically centered with the image

  doc.setFontSize(12);
  doc.addImage(Welcome, 'JPEG', 30, startY, imageWidth, imageHeight);
  doc.text('Thank you for your purchase! We hope to see you again!', 45, messageY);
  doc.addImage(Welcome, 'JPEG', 160, startY, imageWidth, imageHeight);

  // Convert to Blob
  const pdfBlob = doc.output('blob');

  // Create FormData to send
  const formData = new FormData();
  formData.append('pdf', pdfBlob, 'invoice.pdf');

  try {
    const response = await fetch('https://kamalicrackers.com/ijwyshs_kamali_backend/upload_pdf.php', {
      method: 'POST',
      body: formData,
    });

    if (response.ok) {
      const data = await response.json();
      console.log('Response from server:', data); // Log the server response
      if (data.pdfUrl) {
        return data.pdfUrl;
      } else {
        throw new Error('No PDF URL returned');
      }
    } else {
      const errorText = await response.text();
      throw new Error(`Failed to upload PDF: ${response.status} - ${errorText}`);
    }
  } catch (error) {
    console.error('Error uploading PDF:', error);
    return null; // Return null if there's an error
  }
};


  const handleShareWhatsApp = async () => {
    const orderDate = new Date(order.order_date).toLocaleDateString();
    const pdfUrl = await handleGeneratePDF(); // Get the PDF URL from the server

    if (!pdfUrl) {
      console.error("PDF URL is undefined");
      return;
    }

    const message = `
      Dear *${order.name}*,

      We are delighted to inform you that your order has been *successfully completed*.

      Thank you for shopping with us!

      If you have any queries, please do not hesitate to contact us.

      Thank you for choosing our store. We hope to serve you again soon!

      Please find your invoice at the following link:
      *${pdfUrl}*

      Best regards,

     *Kamali Crackers Team*
      Shop Owner Contact: +91 9005900858
      Shop Website: https://kamalicrackers.com/
        

          `;

    const clientPhoneNumber = order.phone.replace(/\D/g, "");
    const url = `https://wa.me/${clientPhoneNumber}?text=${encodeURIComponent(
      message.trim()
    )}`;
    window.open(url, "_blank");
  };

  if (error) {
    return <Typography>{error}</Typography>;
  }

  return (
    <Box
      m="20px"
      sx={{
        // Responsive styles
        "@media (max-width: 768px)": {
          margin: "0",
          marginLeft: "-7%",
          marginBottom: "20%",
        },
        "@media (max-width: 480px)": {
          margin: "0 ",
          marginLeft: "-7%",
          marginBottom: "20%",
          marginRight: "0%",
        },
      }}
    >
      {order ? (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb="15px"
            sx={{
              // Responsive styles
              "@media (max-width: 768px)": {
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              },
              "@media (max-width: 480px)": {
                flexDirection: "column",
                alignItems: "flex-start",
              },
            }}
          >
            <Box textAlign={"left"}>
              <Typography variant="h6" color="#0056b3" fontWeight="bold">
                Customer Details
              </Typography>
              <Typography>
                <strong>Name:</strong> {order.name}
              </Typography>
              <Typography>
                <strong>Address:</strong>{" "}
                {typeof order.address === "string" && order.address.length > 50
                  ? `${order.address.substring(0, 50)}...`
                  : order.address}
              </Typography>
              <Typography>
                <strong>Contact No:</strong> {order.phone}
              </Typography>{" "}
              {/* Display the phone number */}
            </Box>
            <Box textAlign={"left"}>
              <Typography
                variant="h6"
                color="#0056b3"
                fontWeight="bold"
                sx={{
                  // Responsive styles
                  "@media (max-width: 768px)": {
                    marginTop: "10px",
                  },
                  "@media (max-width: 480px)": {
                    marginTop: "10px",
                  },
                }}
              >
                {" "}
                Order Details
              </Typography>
              <Typography>
                <strong>Order ID:</strong> {order.id}
              </Typography>
              <Typography>
                <strong>Order Date:</strong>{" "}
                {new Date(order.order_date).toLocaleDateString()}
              </Typography>
            </Box>
          </Box>
          <Box m="20px 0">
            <Typography
              variant="h6"
              gutterBottom
              textAlign={"left"}
              color="#0056b3"
              fontWeight="bold"
            >
              Order Items
            </Typography>
            <TableContainer
              component={Paper}
              style={{
                maxHeight: orderItems.length > 4 ? 400 : "auto",
                overflowY: "auto",
              }}
            >
              <Table aria-label="order items table" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell className="table-cell">S.No</TableCell>
                    <TableCell className="table-cell">Item Name</TableCell>
                    <TableCell className="table-cell">Quantity</TableCell>
                    <TableCell className="table-cell">Price Per Unit</TableCell>
                    <TableCell className="table-cell">Amount</TableCell>
                    <TableCell className="table-cell">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderItems.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell className="table-cell">{index + 1}</TableCell>
                      <TableCell className="table-cell">
                        {editingIndex === index ? (
                          <TextField
                            name="item_name"
                            value={editedItem.item_name}
                            onChange={handleChange}
                          />
                        ) : (
                          item.item_name
                        )}
                      </TableCell>
                      <TableCell className="table-cell">
                        {editingIndex === index ? (
                          <TextField
                            name="item_quantity"
                            type="number"
                            value={editedItem.item_quantity}
                            onChange={handleChange}
                          />
                        ) : (
                          item.item_quantity
                        )}
                      </TableCell>
                      <TableCell className="table-cell">
                        {editingIndex === index ? (
                          <TextField
                            name="item_discounted_price"
                            type="number"
                            value={editedItem.item_discounted_price}
                            onChange={handleChange}
                          />
                        ) : (
                          item.item_discounted_price
                        )}
                      </TableCell>
                      <TableCell className="table-cell">
                        {item.item_quantity *
                          parseFloat(item.item_discounted_price)}
                      </TableCell>
                      <TableCell className="table-cell">
                        {editingIndex === index ? (
                          <IconButton onClick={() => handleSave(index)}>
                            <SaveIcon />
                          </IconButton>
                        ) : (
                          <IconButton onClick={() => handleEdit(index)}>
                            <EditIcon />
                          </IconButton>
                        )}
                        <IconButton onClick={() => handleDelete(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell className="table-cell" colSpan={4} align="right">
                      <Typography variant="h6">Total Amount</Typography>
                    </TableCell>
                    <TableCell className="table-cell">
                      <Typography variant="h6">
                        {calculateTotalAmount()}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-cell"></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <div className="save_and_deliver">
            <Box>
              <ButtonGroup variant="contained" color="primary">
                <Button onClick={handlePrint}>Print</Button>
                <Button onClick={handleDropdownClick}>
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleShareWhatsApp}>
                  Share on WhatsApp
                </MenuItem>
              </Menu>
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleDeliverOrder();
                handleSaveOrder(); // Call handleSaveOrder as well
              }}
              disabled={orderStatus === "completed"} // Disable button if already completed
              sx={{ ml: 1, backgroundColor: "#0098db" }} // Add left margin and set background color
            >
              {orderStatus === "completed"
                ? "Order Completed"
                : "Complete Order"}
            </Button>
          </div>
        </>
      ) : (
        <Typography>Select an order to view details</Typography>
      )}
    </Box>
  );
};

export default SaleOrders;
