import React, { useState, useEffect } from "react";
import POSContent from "./POSContent";
import {
  generateThermalBillHTML,
  updateTotal,
  getTotalQuantity,
} from "./posUtils";
import "./Pos.css";

const POS = () => {
  const [tabs, setTabs] = useState([]);
  const [activeTabId, setActiveTabId] = useState(null);

  // ----------------------------keyboard shortcuts-----------------------------------
  useEffect(() => {
    // Handle keyboard shortcuts
    const handleKeydown = (event) => {
      if (event.ctrlKey && event.key === "+") {
        event.preventDefault();
        console.log("Custom Ctrl++ action");
        // Add your custom action here
        if (event.ctrlKey && event.key === "+") {
          document.getElementById("newbill").click(); // Example: Focus on field with id 'field1'
        }
      }

      if (event.ctrlKey && event.key === "-") {
        event.preventDefault();
        console.log("Custom Ctrl+- action");
        // Add your custom action here
        if (event.ctrlKey && event.key === "-") {
          document.getElementById("closebill").click();
        }
        if (activeTabId !== null) {
          handleCloseTab(activeTabId);
        }
      }

      // Add more shortcuts as needed
    };

    window.addEventListener("keydown", handleKeydown);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeydown);
    };
  }, []);

  // --------------------------------------------------------------------------------------

  const switchToTab = (tabId) => {
    setActiveTabId(tabId);
  };

  const handleCloseTab = (tabId) => {
    setTabs((prevTabs) => prevTabs.filter((tab) => tab.id !== tabId));
    if (activeTabId === tabId) {
      setActiveTabId(null);
    }
  };

  const setDefaultCustomerDetails = (tabId) => {
    const customerNameInput = document.querySelector(
      `#pos-tab-${tabId} .customer-details input[placeholder="Name"]`
    );
    const customerPhoneInput = document.querySelector(
      `#pos-tab-${tabId} .customer-details input[placeholder="Phone"]`
    );
  
    if (!customerNameInput.value.trim()) {
      customerNameInput.value = "Walk-in customer";
    }
  
    if (!customerPhoneInput.value.trim()) {
      customerPhoneInput.value = "+91 XXXXX - XXXXX";
    }
  };

  const handleSaveOrder = (tabId) => {
    // Get the form elements
    const customerNameInput = document.querySelector(
      `#pos-tab-${tabId} .customer-details input[placeholder="Name"]`
    );
    const customerPhoneInput = document.querySelector(
      `#pos-tab-${tabId} .customer-details input[placeholder="Phone"]`
    );
    const discountsInput = document.querySelector(
      `#pos-tab-${tabId} .discounts input`
    );
  
    // Set default values if not 
    
    if (!customerPhoneInput.value.trim()) {
      customerPhoneInput.value = "+91 XXXXX - XXXXX";
    }

    if (!customerNameInput.value.trim()) {
      customerNameInput.value = "Walk-in customer";
    }
  
    // Ensure DOM updates are applied
    setTimeout(() => {
      const tableBody = document.getElementById(`item-table-${tabId}`);
      const amountReceivedElement = document.getElementById(
        `amount-received-${tabId}`
      );
      const balanceElement = document.getElementById(`balance-${tabId}`);
      const cashierNameSelect = document.querySelector(
        `#pos-tab-${tabId} .cash-upi .payment-method:nth-of-type(1) select`
      );
      const paymentModeSelect = document.querySelector(
        `#pos-tab-${tabId} .cash-upi .payment-method:nth-of-type(2) select`
      );
  
      const amountReceived = parseFloat(amountReceivedElement.value) || 0;
      const balance = parseFloat(balanceElement.value.replace("₹", "")) || 0;
  
      if (tableBody.rows.length === 0) {
        alert("The bill is empty. Please select at least one item to proceed.");
        return;
      }
  
      const customerName = customerNameInput.value.trim();
      const customerPhone = customerPhoneInput.value.trim();
      const cashierName = cashierNameSelect.value;
      const paymentMode = paymentModeSelect.value;
  
      const subTotalElement = document.getElementById(`sub-total-${tabId}`);
      const totalAmountElement = document.getElementById(`total-amount-${tabId}`);
  
      const items = Array.from(tableBody.rows).map((row) => {
        const cells = row.cells;
        return {
          id: cells[1].innerText,
          name: cells[2].innerText,
          quantity: parseInt(cells[3].innerText.trim()),
          price: parseFloat(cells[4].innerText.trim()),
          originalPrice: parseFloat(cells[5].innerText.trim()),
          total: parseFloat(cells[6].innerText.trim()),
        };
      });
  
      const orderData = {
        customerName,
        customerPhone,
        subTotal: parseFloat(
          subTotalElement.innerText.replace("Sub Total: ₹", "")
        ),
        totalAmount: parseFloat(
          totalAmountElement.innerText.replace(/[^0-9.-]+/g, "")
        ),
        discounts: parseFloat(discountsInput.value.replace("₹", "")),
        totalItems: items.length,
        items,
        amountReceived,
        balance,
        cashierName,
        paymentMode,
      };
  
      fetch("https://kamalicrackers.com/ijwyshs_kamali_backend/save_order.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(orderData),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          if (data.success) {
            console.log("Order saved successfully:", data);
            alert("Order saved successfully!");
  
            // Generate and open thermal bill
            const thermalBillHTML = generateThermalBillHTML({
              ...orderData,
              invoiceNumber: data.invoice_id, // Pass invoice_id as invoiceNumber
            });
            const newWindow = window.open("", "", "width=300,height=500");
            newWindow.document.write(thermalBillHTML);
            newWindow.document.close();
            newWindow.print();
  
            // Clear the bill content after successful save
            clearBillContent(tabId);
          } else {
            throw new Error(data.message || "Failed to save order.");
          }
        })
        .catch((error) => {
          console.error("Failed to save order:", error);
          alert("Failed to save order. Please try again.");
        });
    }, 0); // Ensure this runs after setting the default values
  };
  
  
  // Function to clear the bill content
  const clearBillContent = (tabId) => {
    const tableBody = document.getElementById(`item-table-${tabId}`);
    tableBody.innerHTML = ""; // Clear the table rows

    const subTotalElement = document.getElementById(`sub-total-${tabId}`);
    const totalAmountElement = document.getElementById(`total-amount-${tabId}`);
    const discountsInput = document.querySelector(
      `#pos-tab-${tabId} .discounts input`
    );
    const amountReceivedElement = document.getElementById(
      `amount-received-${tabId}`
    );
    const balanceElement = document.getElementById(`balance-${tabId}`);
    const customerNameInput = document.querySelector(
      `#pos-tab-${tabId} .customer-details input[placeholder="Name"]`
    );
    const customerPhoneInput = document.querySelector(
      `#pos-tab-${tabId} .customer-details input[placeholder="Phone"]`
    );

    // Reset bill details
    subTotalElement.innerText = "Sub Total: ₹0.00";
    totalAmountElement.innerText =
      "Total Amount (Items: 0, Quantity: 0): ₹0.00";
    discountsInput.value = "₹0.00";
    amountReceivedElement.value = "₹0.00";
    balanceElement.value = "₹0.00";
    customerNameInput.value = "";
    customerPhoneInput.value = "";
  };

  const handleSelectItem = (item, tabId) => {
    const tableBody = document.getElementById(`item-table-${tabId}`);
  
    let existingRow = null;
    Array.from(tableBody.rows).forEach((row) => {
      if (row.cells[1].innerText === item.id.toString()) {
        existingRow = row;
      }
    });
  
    if (existingRow) {
      const qtyElement = existingRow.cells[3].querySelector(".qty-input");
      const priceElement = existingRow.cells[4].querySelector(".price-input");
      let qty = parseInt(qtyElement.innerText.trim());
      const pricePerUnit = parseFloat(priceElement.innerText.trim());
  
      if (!isNaN(qty) && !isNaN(pricePerUnit)) {
        qty += 1;
        qtyElement.innerText = qty;
  
        const totalElement = existingRow.cells[6];
        const total = qty * pricePerUnit;
        totalElement.innerText = total.toFixed(2);
  
        // Update bill details
        calculateBillDetails(tabId);
      }
    } else {
      const newRow = document.createElement("tr");
      newRow.innerHTML = `
        <td>${tableBody.rows.length + 1}</td>
        <td>${item.id}</td>
        <td style="max-width: 150px; white-space: normal; word-wrap: break-word; overflow-wrap: break-word;text-align: left;">
          ${item.name}
        </td>
        <td>
          <div contenteditable="true" class="qty-input" id="quantity">1</div>
        </td>
        <td>
          <div contenteditable="true" class="price-input">${item.discounted_price.toFixed(2)}</div>
        </td>
        <td>${item.original_price.toFixed(2)}</td>
        <td class="total-cell">${item.discounted_price.toFixed(2)}</td>
        <td>
          <button class="delete-button">⛔</button>
        </td>
      `;
      tableBody.appendChild(newRow);
  
      const qtyElement = newRow.cells[3].querySelector(".qty-input");
      const priceElement = newRow.cells[4].querySelector(".price-input");
      const qty = parseInt(qtyElement.innerText.trim());
      const pricePerUnit = parseFloat(priceElement.innerText.trim());
  
      if (!isNaN(qty) && !isNaN(pricePerUnit)) {
        const totalElement = newRow.cells[6];
        const total = qty * pricePerUnit;
        totalElement.innerText = total.toFixed(2);
  
        // Update bill details
        calculateBillDetails(tabId);
      }
  
      qtyElement.addEventListener("input", () => {
        updateTotal(newRow);
        calculateBillDetails(tabId);
      });
  
      priceElement.addEventListener("input", () => {
        updateTotal(newRow);
        calculateBillDetails(tabId);
      });
  
      const deleteButton = newRow.querySelector(".delete-button");
      deleteButton.addEventListener("click", () => {
        tableBody.removeChild(newRow);
        updateSerialNumbers(tableBody); // Update serial numbers after deleting a row
        calculateBillDetails(tabId);
      });
    }
  };
  
  
  const updateSerialNumbers = (tableBody) => {
    Array.from(tableBody.rows).forEach((row, index) => {
      row.cells[0].innerText = index + 1;
    });
  };

  const calculateBillDetails = (tabId) => {
    const tableBody = document.getElementById(`item-table-${tabId}`);
    let subtotal = 0;
    let mrpTotal = 0;

    Array.from(tableBody.rows).forEach((row) => {
      const totalCell = row.cells[6];
      subtotal += parseFloat(totalCell.innerText);

      const qty = parseInt(
        row.cells[3].querySelector(".qty-input").innerText.trim(),
        10
      );
      const mrpPerUnit = parseFloat(row.cells[5].innerText);

      if (!isNaN(qty) && !isNaN(mrpPerUnit)) {
        mrpTotal += qty * mrpPerUnit;
      }
    });

    const subTotalElement = document.getElementById(`sub-total-${tabId}`);
    const totalAmountElement = document.getElementById(`total-amount-${tabId}`);
    const discountsElement = document.querySelector(
      `#pos-tab-${tabId} .discounts input`
    );

    if (subTotalElement && totalAmountElement && discountsElement) {
      subTotalElement.innerText = `Sub Total: ₹${subtotal.toFixed(2)}`;
      totalAmountElement.innerText = `Total Amount (Items: ${
        tableBody.rows.length
      }, Quantity: ${getTotalQuantity(tableBody)}): ₹${subtotal.toFixed(2)}`;

      const discount = mrpTotal - subtotal;
      discountsElement.value = `₹${discount.toFixed(2)}`;
    }

    // Calculate the balance
    const amountReceivedElement = document.getElementById(
      `amount-received-${tabId}`
    );
    const balanceElement = document.getElementById(`balance-${tabId}`);

    if (amountReceivedElement && balanceElement) {
      const amountReceived = parseFloat(amountReceivedElement.value);
      const balance = subtotal - (isNaN(amountReceived) ? 0 : amountReceived);
      balanceElement.value = `₹${balance.toFixed(2)}`;
    }
  };

  const handleNewTab = () => {
    const newTabId = Date.now();
    const newTab = {
      id: newTabId,
      title: `New Bill`,
      content: (
        <POSContent
          tabId={newTabId}
          handleSaveOrder={handleSaveOrder}
          handleSelectItem={handleSelectItem}
          calculateBillDetails={calculateBillDetails}
          handleNewTab={handleNewTab}
          handleCloseTab={handleCloseTab}
        />
      ),
    };

    setTabs((prevTabs) => [...prevTabs, newTab]);
    setActiveTabId(newTabId);
  };

  useState(() => {
    handleNewTab();
  }, []);

  return (
    <div className="tabbed-panels">
      <div className="tabs">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className="tab"
            onClick={() => switchToTab(tab.id)}
            style={{
              backgroundColor:
                tab.id === activeTabId ? "#007bff" : "transparent",
              color: tab.id === activeTabId ? "#ffffff" : "#000000",
            }}
          >
            <span>{tab.title}</span>
            <span className="close" id="closebill" onClick={() => handleCloseTab(tab.id)}>
              x
            </span>
          </div>
        ))}
        <div className="new-bill" id="newbill" onClick={handleNewTab}>
          +
        </div>
      </div>
      <div className="tab-content">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={tab.id === activeTabId ? "pos-tab active" : "pos-tab"}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default POS;
