import "./Pos.css";


export const generateThermalBillHTML = (orderData) => {
    const { customerName, items, subTotal, discounts, invoiceNumber, amountReceived, balance, cashierName, paymentMode } = orderData;
    const formatDate = (date) => {
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const year = d.getFullYear();
      return `${day}-${month}-${year}`;
    };
  
    const date = formatDate(new Date());
  
  
    let itemsHTML = items.map((item) => `
      <tr>
        <td>${item.name}</td>
        <td>${item.quantity}</td>
        <td>${item.price.toFixed(2)}</td>
        <td>${item.total.toFixed(2)}</td>
      </tr>
    `).join("");
  
    return `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>POS Thermal Print Bill</title>
        <style>
          body {
            font-family: 'Courier New', Courier, monospace;
            font-size: 10px;
            width: 300px;
            margin: 0 auto;
            padding: 0;
          }
          .header, .footer {
            text-align: center;
          }
          .content {
            margin-top: 10px;
            margin-bottom: 10px;
          }
          .content table {
            width: 100%;
            border-collapse: collapse;
          }
          .content th, .content td {
            text-align: left;
            padding: 2px 0;
          }
          .content th {
            border-bottom: 1px solid #000;
          }
          .content td {
            border-bottom: 1px dotted #000;
          }
          .content th, .content td {
            padding-left: 5px;
            padding-right: 5px;
          }
          .total {
            text-align: right;
          }
          .terms {
            text-align: center;
            margin-top: 10px;
          }
          .invoice-details {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
          }
          .invoice-details-left {
            text-align: left;
          }
          .invoice-details-right {
            text-align: right;
          }
        </style>
      </head>
      <body>
        <div class="header">
          <p><strong>Kamali Crackers</strong></p>
          <p>Ph.No.: 9005900858</p>
        </div>
        <div class="content">
          <div class="invoice-details">
            <div class="invoice-details-left">
              <p>Invoice No: ${invoiceNumber}</p>
              <p>Date: ${date}</p>
              <p>Customer: ${customerName}</p>
            </div>
            <div class="invoice-details-right">
              <p>Cashier: ${cashierName}</p>
              <p>Payment Mode: ${paymentMode}</p>
            </div>
          </div>
          <table>
            <tr>
              <th>Item Name</th>
              <th>Qty</th>
              <th>Price</th>
              <th>Amount</th>
            </tr>
            ${itemsHTML}
          </table>
          <br><br>
          <table>
            <tr>
              <td>No Of items:</td>
              <td class="item">${items.length}</td>
            </tr>
            <tr>
              <td>Total</td>
              <td class="total">${subTotal.toFixed(2)}</td>
            </tr>
            <tr>
              <td>Discounts</td>
              <td class="total">${discounts.toFixed(2)}</td>
            </tr>
            <tr>
              <td>Received</td>
              <td class="total">${amountReceived.toFixed(2)}</td>
            </tr>
            <tr>
              <td>Balance</td>
              <td class="total">${balance.toFixed(2)}</td>
            </tr>
          </table>
        </div>
        <div class="footer">
          <p>Thanks for doing business with us!</p>
        </div>
      </body>
      </html>
    `;
  };
 
  
  export const updateTotal = (row) => {
    const qtyElement = row.cells[3].querySelector(".qty-input");
    const priceElement = row.cells[4].querySelector(".price-input");
    const totalElement = row.cells[6];

    const qty = parseInt(qtyElement.innerText.trim());
    const pricePerUnit = parseFloat(priceElement.innerText.trim());

    if (!isNaN(qty) && !isNaN(pricePerUnit)) {
      const total = qty * pricePerUnit;
      totalElement.innerText = total.toFixed(2);
    }
  };
  
  export const getTotalQuantity = (tableBody) => {
    let totalQuantity = 0;
    Array.from(tableBody.rows).forEach((row) => {
      const qty = parseInt(
        row.cells[3].querySelector(".qty-input").innerText.trim()
      );
      if (!isNaN(qty)) {
        totalQuantity += qty;
      }
    });
    return totalQuantity;
  };
  