import React, { useState, useEffect } from "react";
import Item from "./Item";
import ItemList from "./ItemList";
import Header from "./Header";

const ItemHome = () => {
  const tabs = ["Add Item", "Item List"];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [marginLeft, setMarginLeft] = useState('0px');
  const [marginBottom, setMarginBottom] = useState('0px');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "Add Item":
        return <Item />;
      case "Item List":
        return <ItemList />;
      default:
        return null;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) { // Mobile view breakpoint
        setMarginLeft('6%');
        setMarginBottom('20%'); // Set margin-bottom for mobile view
      } else {
        setMarginLeft('0px');
        setMarginBottom('0px'); // Reset margin-bottom for desktop view
      }
    };

    handleResize(); // Set initial value
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div style={{ marginLeft, marginBottom }}>
      <Header tabs={tabs} onTabClick={handleTabClick} />
      {renderTabContent()}
    </div>
  );
};

export default ItemHome;
