import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FavoriteContext } from '../Shop/FavoriteContext';
import { CartContext } from '../Shop/CartContext';
import DefaultImage from '../../../Assets/DefaultImage.png';
import './FavoritesPage.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";


const FavoritesPage = () => {
  const { favorites, removeFavorite } = useContext(FavoriteContext);
  const { addToCart, increaseQuantity, decreaseQuantity, cart } = useContext(CartContext);
  const [addedToCart, setAddedToCart] = useState({});
  const navigate = useNavigate();

  const handleAddToCart = (item) => {
    addToCart(item);
    setAddedToCart((prevState) => ({
      ...prevState,
      [item.id]: true,
    }));
  };

  return (
    <div className="mv-favorites">
      <div className="favoritesPage1">
        <div className="orderSummary1">
          <div className="summaryHeader1">
            <h3>My Wishlist</h3>
            <span className="totalItems1">Items: {favorites.length}</span>
          </div>
          <hr className="dummyLine1" />
          <div className="cartItems1">
            {favorites.map((item) => (
              <div key={item.id} className="cartItem1">
                <img
                  src={
                    item.image_url
                      ? `https://kamalicrackers.com/ijwyshs_kamali_backend/${item.image_url}`
                      : DefaultImage
                  }
                  alt={item.name}
                  className="cartItemImage1"
                />
                <div className="cartItemDetails1">
                  <div className="itemInfo1">
                    <p className="itemName1">{item.name}</p>
                    <p className="itemPrice1">
                      ₹{Number(item.discounted_price).toFixed(2)}
                    </p>
                  </div>
                  {cart.find((cartItem) => cartItem.id === item.id) ? (
                    <div className="itemControls1">
                      <button
                        className="quantityButton1"
                        onClick={() => decreaseQuantity(item.id)}
                      >
                        -
                      </button>
                      <span className="itemQuantity1">
                        {cart.find((cartItem) => cartItem.id === item.id).quantity}
                      </span>
                      <button
                        className="quantityButton1"
                        onClick={() => increaseQuantity(item.id)}
                      >
                        +
                      </button>
                    </div>
                  ) : (
                    <button
                      className="Fv-add-to-cart-button"
                      onClick={() => handleAddToCart(item)}
                    >
                      Add
                    </button>
                  )}
                  <button
                  className="removeButton1"
                  onClick={() => removeFavorite(item.id)}
                >
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    style={{ marginRight: "5px" }}
                  />
                  <div className="cp-remove">Remove</div>
                </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FavoritesPage;
