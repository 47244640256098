import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  Typography,
  useTheme,
  Menu,
  MenuItem,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { tokens } from "../../theme";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import Header from "../../Components/Header";
import BarChart from "../../Components/BarChart";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import StatBox from "../../Components/StatBox";
import RecentOrders from "../../Components/RecentOrders";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // State for the dropdown menus
  const [anchorElSales, setAnchorElSales] = useState(null);
  const openSales = Boolean(anchorElSales);
  const [anchorElOrders, setAnchorElOrders] = useState(null);
  const openOrders = Boolean(anchorElOrders);
  const [anchorElCompleted, setAnchorElCompleted] = useState(null);
  const openCompleted = Boolean(anchorElCompleted);
  const [anchorElPending, setAnchorElPending] = useState(null);
  const openPending = Boolean(anchorElPending);

  // State for total sales, total orders, completed orders, and pending orders
  const [totalSales, setTotalSales] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [completedOrders, setCompletedOrders] = useState(0);
  const [pendingOrders, setPendingOrders] = useState(0);

  // State for the selected date range for total sales
  const [dateRangeSales, setDateRangeSales] = useState("all");
  const [dateRangeOrders, setDateRangeOrders] = useState("all");
  const [dateRangeCompleted, setDateRangeCompleted] = useState("all");
  const [dateRangePending, setDateRangePending] = useState("all");

  // State for custom date range inputs
  const [customFromDate, setCustomFromDate] = useState("");
  const [customToDate, setCustomToDate] = useState("");

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchTotalSales = async (dateRange) => {
      try {
        const response = await fetch(
          `https://kamalicrackers.com/ijwyshs_kamali_backend/get_total_sales.php?dateRange=${dateRange}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        if (data.status === "success") {
          setTotalSales(data.total_sales);
        } else {
          console.error("Error: ", data.message);
        }
      } catch (error) {
        console.error("Error fetching total sales:", error);
      }
    };

    const fetchTotalOrders = async (dateRange) => {
      try {
        const response = await fetch(
          `https://kamalicrackers.com/ijwyshs_kamali_backend/get_total_orders.php?dateRange=${dateRange}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        if (data.status === "success") {
          setTotalOrders(data.total_orders);
        } else {
          console.error("Error: ", data.message);
        }
      } catch (error) {
        console.error("Error fetching total orders:", error);
      }
    };

    const fetchCompletedOrders = async (dateRange) => {
      try {
        const response = await fetch(
          `https://kamalicrackers.com/ijwyshs_kamali_backend/get_completed_orders.php?dateRange=${dateRange}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        if (data.status === "success") {
          setCompletedOrders(data.completed_orders);
        } else {
          console.error("Error: ", data.message);
        }
      } catch (error) {
        console.error("Error fetching completed orders:", error);
      }
    };

    const fetchPendingOrders = async (dateRange) => {
      try {
        const response = await fetch(
          `https://kamalicrackers.com/ijwyshs_kamali_backend/get_pending_orders.php?dateRange=${dateRange}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        if (data.status === "success") {
          setPendingOrders(data.pending_orders);
        } else {
          console.error("Error: ", data.message);
        }
      } catch (error) {
        console.error("Error fetching pending orders:", error);
      }
    };

    fetchTotalSales(dateRangeSales);
    fetchTotalOrders(dateRangeOrders);
    fetchCompletedOrders(dateRangeCompleted);
    fetchPendingOrders(dateRangePending);
  }, [dateRangeSales, dateRangeOrders, dateRangeCompleted, dateRangePending]);

  const handleClick = (setAnchorEl) => (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (setAnchorEl) => () => {
    setAnchorEl(null);
  };

  const handleDateRangeChangeSales = (range) => async () => {
    setAnchorElSales(null);
    setDateRangeSales(range);
  };

  const handleDateRangeChangeOrders = (range) => async () => {
    setAnchorElOrders(null);
    setDateRangeOrders(range);
  };

  const handleDateRangeChangeCompleted = (range) => async () => {
    setAnchorElCompleted(null);
    setDateRangeCompleted(range);
  };

  const handleDateRangeChangePending = (range) => async () => {
    setAnchorElPending(null);
    setDateRangePending(range);
  };

  const handleCustomDateRangeSubmit =
    (setDateRange, setCustomFromDate, customFromDate, customToDate) =>
    async () => {
      if (customFromDate && customToDate) {
        setDateRange("custom");
        setCustomFromDate(customFromDate);
        setCustomToDate(customToDate);
        setAnchorElSales(null); // Close the dropdown after apply
        setAnchorElOrders(null); // Close the dropdown after apply
        setAnchorElCompleted(null); // Close the dropdown after apply
        setAnchorElPending(null); // Close the dropdown after apply
      } else {
        console.error("Please select both From and To dates.");
      }
    };

  return (
    <Box m="20px" ml={isMobile ? 3 : 5} height={"100vh"}>
      {/* HEADER */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection={isMobile ? "column" : "row"}
      >
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns={isMobile ? "repeat(1, 1fr)" : "repeat(12, 1fr)"}
        gridAutoRows={isMobile ? "auto" : "140px"}
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn={isMobile ? "span 12" : "span 3"}
          backgroundColor="#64a758"
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="relative"
          p={isMobile ? 2 : 0}
        >
          <StatBox
            title={`₹ ${Math.round(totalSales)}`}
            subtitle="Total Sale"
            icon={
              <AccountBalanceIcon sx={{ color: "white", fontSize: "26px" }} />
            }
          />
          <IconButton
            aria-controls={openSales ? "menu-total-sale" : undefined}
            aria-haspopup="true"
            onClick={handleClick(setAnchorElSales)}
            sx={{ position: "absolute", top: 0, right: 0 }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="menu-total-sale"
            anchorEl={anchorElSales}
            keepMounted
            open={openSales}
            onClose={handleClose(setAnchorElSales)}
          >
            <MenuItem onClick={handleDateRangeChangeSales("all")}>All</MenuItem>
            <MenuItem onClick={handleDateRangeChangeSales("today")}>
              Today
            </MenuItem>
            <MenuItem onClick={handleDateRangeChangeSales("this_month")}>
              This Month
            </MenuItem>
            <MenuItem>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  padding: "10px",
                }}
              >
                <Typography variant="subtitle2" gutterBottom>
                  Custom Range
                </Typography>
                <TextField
                  id="custom-from-date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={customFromDate}
                  onChange={(e) => setCustomFromDate(e.target.value)}
                  sx={{ mb: 1, width: "100%" }}
                />
                <TextField
                  id="custom-to-date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={customToDate}
                  onChange={(e) => setCustomToDate(e.target.value)}
                  sx={{ mb: 1, width: "100%" }}
                />
                <Button
                  variant="contained"
                  onClick={handleCustomDateRangeSubmit(
                    setDateRangeSales,
                    setCustomFromDate,
                    customFromDate,
                    customToDate
                  )}
                  sx={{ alignSelf: "flex-end" }}
                >
                  Apply
                </Button>
              </Box>
            </MenuItem>
          </Menu>
        </Box>
        {/* Total Orders StatBox */}
        <Box
          gridColumn={isMobile ? "span 12" : "span 3"}
          backgroundColor="#51bacd"
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="relative"
          p={isMobile ? 2 : 0}
        >
          <StatBox
            title={`${totalOrders}`}
            subtitle="Total Orders"
            icon={
              <ShoppingCartIcon sx={{ color: "white", fontSize: "26px" }} />
            }
          />
          <IconButton
            aria-controls={openOrders ? "menu-total-orders" : undefined}
            aria-haspopup="true"
            onClick={handleClick(setAnchorElOrders)}
            sx={{ position: "absolute", top: 0, right: 0 }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="menu-total-orders"
            anchorEl={anchorElOrders}
            keepMounted
            open={openOrders}
            onClose={handleClose(setAnchorElOrders)}
          >
            <MenuItem onClick={handleDateRangeChangeOrders("all")}>
              All
            </MenuItem>
            <MenuItem onClick={handleDateRangeChangeOrders("today")}>
              Today
            </MenuItem>
            <MenuItem onClick={handleDateRangeChangeOrders("this_month")}>
              This Month
            </MenuItem>
            <MenuItem>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  padding: "10px",
                }}
              >
                <Typography variant="subtitle2" gutterBottom>
                  Custom Range
                </Typography>
                <TextField
                  id="custom-from-date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={customFromDate}
                  onChange={(e) => setCustomFromDate(e.target.value)}
                  sx={{ mb: 1, width: "100%" }}
                />
                <TextField
                  id="custom-to-date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={customToDate}
                  onChange={(e) => setCustomToDate(e.target.value)}
                  sx={{ mb: 1, width: "100%" }}
                />
                <Button
                  variant="contained"
                  onClick={handleCustomDateRangeSubmit(
                    setDateRangeOrders,
                    setCustomFromDate,
                    customFromDate,
                    customToDate
                  )}
                  sx={{ alignSelf: "flex-end" }}
                >
                  Apply
                </Button>
              </Box>
            </MenuItem>
          </Menu>
        </Box>

        {/* Completed Orders StatBox */}
        <Box
          gridColumn={isMobile ? "span 12" : "span 3"}
          backgroundColor="#f09c3a"
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="relative"
          p={isMobile ? 2 : 0}
        >
          <StatBox
            title={`${completedOrders}`}
            subtitle="Completed Orders"
            icon={<DoneAllIcon sx={{ color: "white", fontSize: "26px" }} />}
          />
          <IconButton
            aria-controls={openCompleted ? "menu-completed-orders" : undefined}
            aria-haspopup="true"
            onClick={handleClick(setAnchorElCompleted)}
            sx={{ position: "absolute", top: 0, right: 0 }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="menu-completed-orders"
            anchorEl={anchorElCompleted}
            keepMounted
            open={openCompleted}
            onClose={handleClose(setAnchorElCompleted)}
          >
            <MenuItem onClick={handleDateRangeChangeCompleted("all")}>
              All
            </MenuItem>
            <MenuItem onClick={handleDateRangeChangeCompleted("today")}>
              Today
            </MenuItem>
            <MenuItem onClick={handleDateRangeChangeCompleted("this_month")}>
              This Month
            </MenuItem>
            <MenuItem>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  padding: "10px",
                }}
              >
                <Typography variant="subtitle2" gutterBottom>
                  Custom Range
                </Typography>
                <TextField
                  id="custom-from-date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={customFromDate}
                  onChange={(e) => setCustomFromDate(e.target.value)}
                  sx={{ mb: 1, width: "100%" }}
                />
                <TextField
                  id="custom-to-date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={customToDate}
                  onChange={(e) => setCustomToDate(e.target.value)}
                  sx={{ mb: 1, width: "100%" }}
                />
                <Button
                  variant="contained"
                  onClick={handleCustomDateRangeSubmit(
                    setDateRangeCompleted,
                    setCustomFromDate,
                    customFromDate,
                    customToDate
                  )}
                  sx={{ alignSelf: "flex-end" }}
                >
                  Apply
                </Button>
              </Box>
            </MenuItem>
          </Menu>
        </Box>

        {/* Pending Orders StatBox */}
        <Box
          gridColumn={isMobile ? "span 12" : "span 3"}
          backgroundColor="#da4942"
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="relative"
          p={isMobile ? 2 : 0}
        >
          <StatBox
            title={`${pendingOrders}`}
            subtitle="Pending Orders"
            icon={
              <PendingActionsIcon sx={{ color: "white", fontSize: "26px" }} />
            }
          />
          <IconButton
            aria-controls={openPending ? "menu-pending-orders" : undefined}
            aria-haspopup="true"
            onClick={handleClick(setAnchorElPending)}
            sx={{ position: "absolute", top: 0, right: 0 }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="menu-pending-orders"
            anchorEl={anchorElPending}
            keepMounted
            open={openPending}
            onClose={handleClose(setAnchorElPending)}
          >
            <MenuItem onClick={handleDateRangeChangePending("all")}>
              All
            </MenuItem>
            <MenuItem onClick={handleDateRangeChangePending("today")}>
              Today
            </MenuItem>
            <MenuItem onClick={handleDateRangeChangePending("this_month")}>
              This Month
            </MenuItem>
            <MenuItem>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  padding: "10px",
                }}
              >
                <Typography variant="subtitle2" gutterBottom>
                  Custom Range
                </Typography>
                <TextField
                  id="custom-from-date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={customFromDate}
                  onChange={(e) => setCustomFromDate(e.target.value)}
                  sx={{ mb: 1, width: "100%" }}
                />
                <TextField
                  id="custom-to-date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={customToDate}
                  onChange={(e) => setCustomToDate(e.target.value)}
                  sx={{ mb: 1, width: "100%" }}
                />
                <Button
                  variant="contained"
                  onClick={handleCustomDateRangeSubmit(
                    setDateRangePending,
                    setCustomFromDate,
                    customFromDate,
                    customToDate
                  )}
                  sx={{ alignSelf: "flex-end" }}
                >
                  Apply
                </Button>
              </Box>
            </MenuItem>
          </Menu>
        </Box>
        {/* ROW 2 */}
        <Box
          gridColumn={isMobile ? "span 12" : "span 8"}
          gridRow="span 0"
          backgroundColor={colors.primary[400]}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {!isMobile && (
            <Box
              width={isMobile ? "100%" : "100%"}
              height={isMobile ? "200px" : "100%"}
            >
              <BarChart dateRange={dateRangeSales} />
            </Box>
          )}
        </Box>

        <RecentOrders />
      </Box>
    </Box>
  );
};

export default Dashboard;
