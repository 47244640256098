import { useState, useContext, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import {
  Box,
  IconButton,
  Typography,
  useTheme,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import { useNavigate, Outlet } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens, ColorModeContext } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import Logo from "../../Assets/DashboardLogo.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Item = ({ title, to, icon, selected, setSelected, onClick }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate(); // Move the useNavigate call inside the component

  const handleClick = () => {
    setSelected(title);
    if (to) {
      navigate(to);
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: selected === title ? "#6870fa" : colors.grey[100],
      }}
      onClick={handleClick}
      icon={icon}
    >
      <Typography>{title}</Typography>
    </MenuItem>
  );
};

const BottomNavbar = ({ selected, setSelected }) => {
  const navigate = useNavigate(); // useNavigate inside the component

  const handleNavChange = (event, newValue) => {
    setSelected(newValue);
    navigate(newValue);
  };

  return (
    <BottomNavigation
      value={selected}
      onChange={handleNavChange}
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        boxShadow: "0 -1px 4px rgba(0, 0, 0, 0.2)",
      }}
    >
      <BottomNavigationAction
        label="Home"
        value="/ijwyshs_admin/dashboard"
        icon={<HomeOutlinedIcon />}
      />
      <BottomNavigationAction
        label="Items"
        value="/ijwyshs_admin/items"
        icon={<CategoryOutlinedIcon />}
      />
      <BottomNavigationAction
        label="Order Sales"
        value="/ijwyshs_admin/ordersale"
        icon={<ShoppingCartOutlinedIcon />}
      />
      <BottomNavigationAction
        label="POS"
        value="/ijwyshs_admin/pos"
        icon={<ReceiptLongIcon />}
      />
    </BottomNavigation>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [isCollapsed, setIsCollapsed] = useState(window.innerWidth < 768);
  const [selected, setSelected] = useState("Dashboard");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const navigate = useNavigate(); // Move the useNavigate call inside the component

  useEffect(() => {
    const handleResize = () => {
      setIsCollapsed(window.innerWidth < 768);
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleBackToHome = () => {
    navigate('/home#'); 
  };

  return (
    <>
      {isMobile ? (
        <>
          <Box
            sx={{
              height: "calc(100vh - 56px)", // Adjust this value if the BottomNavigation height changes
              overflow: "auto",
              paddingBottom: "56px", // Make room for the bottom navbar
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            {/* Content for the selected page goes here */}
            <Outlet />
          </Box>
          <BottomNavbar selected={selected} setSelected={setSelected} />
        </>
      ) : (
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              height: "100vh",
              width: isCollapsed ? "80px" : "250px",
              position: "fixed",
              transition: "width 0.3s ease",
              zIndex: 10, // Ensure sidebar is above content
              "& .pro-sidebar-inner": {
                background: `${colors.primary[400]} !important`,
              },
              "& .pro-icon-wrapper": {
                backgroundColor: "transparent !important",
              },
              "& .pro-inner-item": {
                padding: "5px 35px 5px 20px !important",
              },
              "& .pro-inner-item:hover": {
                color: "#868dfb !important",
              },
              "& .pro-menu-item.active": {
                color: "#6870fa !important",
              },
            }}
          >
            <ProSidebar collapsed={isCollapsed}>
              <Menu iconShape="square">
                <MenuItem
                  onClick={() => setIsCollapsed(!isCollapsed)}
                  icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                  style={{
                    margin: "10px 0 20px 0",
                    color: colors.grey[100],
                  }}
                >
                  {!isCollapsed && (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      ml="15px"
                    >
                      <Typography variant="h3" color={colors.grey[100]}>
                        DASHBOARD
                      </Typography>
                      <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                        <MenuOutlinedIcon />
                      </IconButton>
                    </Box>
                  )}
                </MenuItem>

                {!isCollapsed && (
                  <Box mb="25px">
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <img
                        onClick={handleBackToHome}
                        alt="profile-user"
                        width="80px"
                        height="80px"
                        src={Logo}
                        style={{ cursor: "pointer", borderRadius: "50%" }}
                      />
                    </Box>
                    <Box textAlign="center">
                      <Typography
                        variant="h3"
                        color={colors.grey[100]}
                        fontWeight="bold"
                        sx={{ m: "10px 0 0 0" }}
                      >
                        Kamali Crackers
                      </Typography>
                      <Typography
                        variant="h5"
                        color={colors.greenAccent[500]}
                        sx={{ mt: 2 }}
                      >
                        Admin
                      </Typography>
                    </Box>
                  </Box>
                )}

                <Box paddingLeft={isCollapsed ? undefined : "10%"}>
                  <Item
                    title="Home"
                    to="/ijwyshs_admin/dashboard"
                    icon={<HomeOutlinedIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Items"
                    to="/ijwyshs_admin/items"
                    icon={<CategoryOutlinedIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Order Sales"
                    to="/ijwyshs_admin/ordersale"
                    icon={<ShoppingCartOutlinedIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="POS"
                    to="/ijwyshs_admin/pos"
                    icon={<ReceiptLongIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Back to Web"
                    to="/home#"
                    icon={<ArrowBackIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </Box>
              </Menu>
            </ProSidebar>
          </Box>
          <Box
            className="content"
            sx={{
              marginLeft: isCollapsed ? "80px" : "250px",
              transition: "margin-left 0.3s ease",
              flexGrow: 1,
              p: 0,
            }}
          >
            <Outlet />
          </Box>
        </Box>
      )}
    </>
  );
};

export default Sidebar;
