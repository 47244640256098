import React from "react";
import { useNavigate } from "react-router-dom";
import "./CustomModal.css";

const CustomModal = ({ show, message, onClose }) => {
  const navigate = useNavigate();

  const handleOKClick = () => {
    onClose();
    navigate("/home");

    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  if (!show) {
    return null;
  }

  return (
    <div className="cm-modalOverlay">
      <div className="cm-modalContent">
        <p>{message}</p>
        <button onClick={handleOKClick} className="cm-closeButton">
          OK
        </button>
      </div>
    </div>
  );
};

export default CustomModal;
