import React, { useState, useContext, useEffect, useRef } from "react";
import "./Header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faHeart, faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { CartContext } from "../Shop/CartContext";
import { FavoriteContext } from "../Shop/FavoriteContext";
import { useNavigate } from "react-router-dom";
import LoginPage from "./LoginPage";
import AdminLogin from "./AdminLogin";
import DefaultImage from "../../../Assets/DefaultImage.png";

function Header() {
  const [activeLink, setActiveLink] = useState("/home");
  const { cart, totalPrice } = useContext(CartContext);
  const { favorites } = useContext(FavoriteContext);
  const [loginOpen, setLoginOpen] = useState(false);
  const [adminLoginOpen, setAdminLoginOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [cartClicked, setCartClicked] = useState(false);
  const [favoritesClicked, setFavoritesClicked] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);

  const handleLinkClick = (link) => {
    setActiveLink(link);
    navigate(link);
    setMenuOpen(false);
  };

  const handleViewCartClick = () => {
    if (cart.length > 0) {
      navigate("/cart");
    } else {
      setCartClicked((prev) => !prev);
      setFavoritesClicked(false); // Hide favorites message if showing
    }
  };

  const handleFavoritesClick = () => {
    if (favorites.length > 0) {
      navigate("/favorites");
    } else {
      setFavoritesClicked((prev) => !prev);
      setCartClicked(false); // Hide cart message if showing
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuOpen &&
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !event.target.closest(".mobile-menu-button")
      ) {
        setMenuOpen(false);
      }

      if (
        !event.target.closest(".auth-cart") &&
        !event.target.closest(".favorites-icon-container") &&
        !event.target.closest(".cart-icon-container")
      ) {
        setCartClicked(false);
        setFavoritesClicked(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [menuOpen]);
  
  const handleBackToHome = () => {
    navigate('/home#'); 
  };

  return (
    <header className="header">
      <button
        className="mobile-menu-button"
        onClick={() => setMenuOpen(!menuOpen)}
      >
        ☰
      </button>
      <div className="logo">
        <h2 onClick={handleBackToHome}>
          <span className="highlight">Kamali</span> Crackers
        </h2>
      </div>
      <nav ref={menuRef} className={`nav ${menuOpen ? "open" : ""}`}>
        <a
          href="#"
          onClick={() => handleLinkClick("/home")}
          className={activeLink === "/home" ? "active" : ""}
        >
          Home
        </a>
        <a
          href="#"
          onClick={() => handleLinkClick("/shop")}
          className={activeLink === "/shop" ? "active" : ""}
        >
          Shop
        </a>
        <a
          href="#"
          onClick={() => handleLinkClick("/quickBuy")}
          className={activeLink === "/quickBuy" ? "active" : ""}
        >
          Quick Buy
        </a>
        <a
          href="#"
          onClick={() => handleLinkClick("/about")}
          className={activeLink === "/about" ? "active" : ""}
        >
          About Us
        </a>
        <a
          href="#"
          onClick={() => handleLinkClick("/contact")}
          className={activeLink === "/contact" ? "active" : ""}
        >
          Contact Us
        </a>
        {/* <a href="#" onClick={() => setLoginOpen(true)}>
          Login / Register
        </a> */}
      </nav>
      <div className="auth-cart">
        <div className="favorites-icon-container" onClick={handleFavoritesClick}>
          <FontAwesomeIcon
            icon={faHeart}
            style={{ color: "red", fontSize: 18, paddingRight: 10 }}
          />
          <span className="favorites-count">{favorites.length}</span>
          {favoritesClicked && favorites.length === 0 && (
            <div className="favorites-dropdown">
              <p>Your favorites list is empty</p>
            </div>
          )}
        </div>
        <div className="cart-icon-container" onClick={handleViewCartClick}>
          <FontAwesomeIcon icon={faCartShopping} />
          <span className="cart-count">{cart.length}</span>
          {cartClicked && cart.length === 0 && (
            <div className="cart-dropdown">
              <p>Your cart is empty</p>
            </div>
          )}
        </div>
        {cart.length > 0 && (
          <a href="#" onClick={handleViewCartClick}>
            ₹{totalPrice.toFixed(2)}
          </a>
        )}
        {loginOpen && (
          <div className="login-popup">
            <div className="login-popup-inner">
              <button
                className="close-button"
                onClick={() => setLoginOpen(false)}
              >
                X
              </button>
              <LoginPage
                onAdminLoginClick={() => {
                  setLoginOpen(false);
                  setAdminLoginOpen(true);
                }}
              />
            </div>
          </div>
        )}
        {adminLoginOpen && (
          <div className="login-popup">
            <div className="login-popup-inner">
              <button
                className="close-button"
                onClick={() => setAdminLoginOpen(false)}
              >
                X
              </button>
              <AdminLogin onClose={() => setAdminLoginOpen(false)} />
            </div>
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;
