import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import "./Pos.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const SearchInput = forwardRef(({ onSearch }, ref) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [focusedIndex, setFocusedIndex] = useState(-1); // Track the focused item index
  const searchResultsRef = useRef(null);
  const searchInputRef = useRef(null); // Reference for the search input field
  const searchItemRefs = useRef([]); // Reference for search result items

  // Expose focus method to parent component
  useImperativeHandle(ref, () => ({
    focus: () => {
      searchInputRef.current.focus();
    }
  }));

  useEffect(() => {
    console.log("Search term changed:", searchTerm);
    if (searchTerm) {
      fetch(`https://kamalicrackers.com/ijwyshs_kamali_backend/search_items.php?q=${searchTerm}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => setSearchResults(data))
        .catch((error) => {
          console.error(
            "There has been a problem with your fetch operation:",
            error
          );
        });
    } else {
      setSearchResults([]);
    }
  }, [searchTerm]);

  const handleSelectItem = (item) => {
    onSearch(item);
    setSearchTerm(""); // Clear search input
    setSearchResults([]); // Clear search results
    setFocusedIndex(-1); // Reset focused index
  };

  const handleKeyDown = (e) => {
    if (searchResults.length > 0) {
      if (e.key === "ArrowDown") {
        setFocusedIndex((prevIndex) => {
          const nextIndex = prevIndex < searchResults.length - 1 ? prevIndex + 1 : prevIndex;
          searchItemRefs.current[nextIndex]?.scrollIntoView({ behavior: "smooth", block: "nearest" });
          return nextIndex;
        });
      } else if (e.key === "ArrowUp") {
        setFocusedIndex((prevIndex) => {
          const nextIndex = prevIndex > 0 ? prevIndex - 1 : 0;
          searchItemRefs.current[nextIndex]?.scrollIntoView({ behavior: "smooth", block: "nearest" });
          return nextIndex;
        });
      } else if (e.key === "Enter" && focusedIndex >= 0) {
        handleSelectItem(searchResults[focusedIndex]);
      }
    }
  };

  useEffect(() => {
    const handleCtrlF = (e) => {
      if (e.ctrlKey && e.key === "f") {
        e.preventDefault();
        searchInputRef.current.focus();
      }
    };

    document.addEventListener("keydown", handleCtrlF);
    return () => {
      document.removeEventListener("keydown", handleCtrlF);
    };
  }, []);

  return (
    <div className="search-container">
      <div className="search-wrapper">
        <FontAwesomeIcon icon={faSearch} className="search-icon" />
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => {
            console.log("Input change:", e.target.value);
            setSearchTerm(e.target.value);
            setFocusedIndex(-1); // Reset focused index on new input
          }}
          className="search-input"
          onKeyDown={handleKeyDown}
          ref={searchInputRef} // Attach ref to the input
        />
        {searchResults.length > 0 && (
          <div className="search-results" ref={searchResultsRef}>
            <table className="search-results-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Item Name</th>
                </tr>
              </thead>
              <tbody>
                {searchResults.map((item, index) => (
                  <tr
                    key={item.id}
                    ref={(el) => (searchItemRefs.current[index] = el)} // Attach ref to each item
                    className={`search-result-item ${
                      index === focusedIndex ? "focused" : ""
                    }`}
                    onClick={() => handleSelectItem(item)}
                  >
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
});

export default SearchInput;
