import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import {
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Button,
} from "@mui/material";
import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import axios from "axios";
import moment from "moment";

const BarChart = ({ isDashboard = false, height = 400 }) => {
  const colors = ["#f09c3a", "#64a758", "#da4942", "#8835ab"];
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dateRange, setDateRange] = useState("this_week");
  const [customFromDate, setCustomFromDate] = useState("");
  const [customToDate, setCustomToDate] = useState("");

  const open = Boolean(anchorEl);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let url = `https://kamalicrackers.com/ijwyshs_kamali_backend/fetch_order_data.php?dateRange=${dateRange}`;

        if (dateRange === "custom") {
          url += `&fromDate=${customFromDate}&toDate=${customToDate}`;
        }

        const response = await axios.get(url);
        console.log("API Response:", response.data);

        if (!response.data || !Array.isArray(response.data)) {
          throw new Error("Error: Invalid data format returned from the server");
        }

        let aggregatedData = {};

        const fromDateMoment = moment(customFromDate);
        const toDateMoment = moment(customToDate);
        const dateDiffInDays = toDateMoment.diff(fromDateMoment, "days");
        const dateDiffInMonths = toDateMoment.diff(fromDateMoment, "months");

        if (dateRange === "this_year") {
          aggregatedData = response.data.reduce((acc, item) => {
            if (item.order_date && item.total_amount) {
              const month = moment(item.order_date).format("YYYY-MM");
              if (!acc[month]) {
                acc[month] = 0;
              }
              acc[month] += parseFloat(item.total_amount);
            }
            return acc;
          }, {});
        } else if (dateRange === "this_month") {
          aggregatedData = response.data.reduce((acc, item) => {
            if (item.order_date && item.total_amount) {
              const dayOfMonth = moment(item.order_date).date();
              const rangeStart = Math.floor((dayOfMonth - 1) / 10) * 10 + 1;
              const rangeEnd = Math.min(
                rangeStart + 9,
                moment(item.order_date).endOf("month").date()
              );
              const key = `${rangeStart}-${rangeEnd} days`;
              if (!acc[key]) {
                acc[key] = 0;
              }
              acc[key] += parseFloat(item.total_amount);
            }
            return acc;
          }, {});
        } else if (dateRange === "this_week") {
          aggregatedData = response.data.reduce((acc, item) => {
            if (item.order_date && item.total_amount) {
              const day = moment(item.order_date).format("YYYY-MM-DD");
              if (!acc[day]) {
                acc[day] = 0;
              }
              acc[day] += parseFloat(item.total_amount);
            }
            return acc;
          }, {});
        } else if (dateRange === "custom") {
          if (dateDiffInDays <= 7) {
            aggregatedData = response.data.reduce((acc, item) => {
              if (item.order_date && item.total_amount) {
                const day = moment(item.order_date).format("YYYY-MM-DD");
                if (!acc[day]) {
                  acc[day] = 0;
                }
                acc[day] += parseFloat(item.total_amount);
              }
              return acc;
            }, {});
          } else if (dateDiffInDays <= 30) {
            aggregatedData = response.data.reduce((acc, item) => {
              if (item.order_date && item.total_amount) {
                const dayOfMonth = moment(item.order_date).date();
                const rangeStart = Math.floor((dayOfMonth - 1) / 10) * 10 + 1;
                const rangeEnd = Math.min(
                  rangeStart + 9,
                  moment(item.order_date).endOf("month").date()
                );
                const key = `${rangeStart}-${rangeEnd} days`;
                if (!acc[key]) {
                  acc[key] = 0;
                }
                acc[key] += parseFloat(item.total_amount);
              }
              return acc;
            }, {});
          } else if (dateDiffInMonths <= 12) {
            aggregatedData = response.data.reduce((acc, item) => {
              if (item.order_date && item.total_amount) {
                const month = moment(item.order_date).format("YYYY-MM");
                if (!acc[month]) {
                  acc[month] = 0;
                }
                acc[month] += parseFloat(item.total_amount);
              }
              return acc;
            }, {});
          } else {
            aggregatedData = response.data.reduce((acc, item) => {
              if (item.order_date && item.total_amount) {
                const year = moment(item.order_date).format("YYYY");
                if (!acc[year]) {
                  acc[year] = 0;
                }
                acc[year] += parseFloat(item.total_amount);
              }
              return acc;
            }, {});
          }
        }

        console.log("Aggregated Data:", aggregatedData);

        const fetchedData = Object.keys(aggregatedData).map((period) => {
          return {
            period,
            amount: aggregatedData[period],
          };
        });

        console.log("Fetched Data:", fetchedData);

        setData(fetchedData);
      } catch (error) {
        console.error("Error fetching the data", error);
        setError(error.message);
      }
    };

    fetchData();
  }, [dateRange, customFromDate, customToDate]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDateRangeChange = (range) => () => {
    if (range === "custom") {
      setCustomFromDate("");
      setCustomToDate("");
    }
    setDateRange(range);
    handleMenuClose();
  };

  const handleCustomDateRangeSubmit = () => {
    setDateRange("custom");
    handleMenuClose();
  };

  console.log("Chart Data:", data);

  if (error && data.length === 0) {
    return <div>Error fetching data: {error}</div>;
  }

  if (data.length === 0) {
    return (
      <Box
        gridColumn="span 4"
        gridRow="span 2"
        padding="20px"
        height={height}
        marginBottom="10px"
        position="relative"
        bgcolor="#f2f0f0"
      >
        <Typography variant="h4" fontWeight="800" color="black">
          Sales Data
        </Typography>
        <IconButton
          aria-controls={open ? "menu-total-sale" : undefined}
          aria-haspopup="true"
          onClick={handleMenuClick}
          sx={{ position: "absolute", top: 15, right: 15, color: "black" }}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="menu-total-sale"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleDateRangeChange("this_week")}>
            This Week
          </MenuItem>
          <MenuItem onClick={handleDateRangeChange("this_month")}>
            This Month
          </MenuItem>
          <MenuItem onClick={handleDateRangeChange("this_year")}>
            This Year
          </MenuItem>
          <MenuItem>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                padding: "10px",
              }}
            >
              <Typography variant="subtitle2" gutterBottom>
                Custom Range
              </Typography>
              <TextField
                id="custom-from-date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={customFromDate}
                onChange={(e) => setCustomFromDate(e.target.value)}
                sx={{ marginBottom: "10px" }}
              />
              <TextField
                id="custom-to-date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={customToDate}
                onChange={(e) => setCustomToDate(e.target.value)}
                sx={{ marginBottom: "10px" }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleCustomDateRangeSubmit}
                sx={{ marginTop: "10px" }}
              >
                Apply
              </Button>
            </Box>
          </MenuItem>
        </Menu>
        <Typography variant="h6" align="center" color="textSecondary" sx={{ marginTop: "20px" }}>
          No orders in this week
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      gridColumn="span 4"
      gridRow="span 2"
      padding="20px"
      height={height}
      marginBottom="10px"
      position="relative"
      bgcolor="#f2f0f0"
    >
      <Typography variant="h4" fontWeight="800" color="black">
        Sales Data
      </Typography>
      <IconButton
        aria-controls={open ? "menu-total-sale" : undefined}
        aria-haspopup="true"
        onClick={handleMenuClick}
        sx={{ position: "absolute", top: 15, right: 15, color: "black" }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="menu-total-sale"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleDateRangeChange("this_week")}>
          This Week
        </MenuItem>
        <MenuItem onClick={handleDateRangeChange("this_month")}>
          This Month
        </MenuItem>
        <MenuItem onClick={handleDateRangeChange("this_year")}>
          This Year
        </MenuItem>
        <MenuItem>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: "10px",
            }}
          >
            <Typography variant="subtitle2" gutterBottom>
              Custom Range
            </Typography>
            <TextField
              id="custom-from-date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={customFromDate}
              onChange={(e) => setCustomFromDate(e.target.value)}
              sx={{ marginBottom: "10px" }}
            />
            <TextField
              id="custom-to-date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={customToDate}
              onChange={(e) => setCustomToDate(e.target.value)}
              sx={{ marginBottom: "10px" }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleCustomDateRangeSubmit}
              sx={{ marginTop: "10px" }}
            >
              Apply
            </Button>
          </Box>
        </MenuItem>
      </Menu>
      <ResponsiveBar
        data={data}
        keys={["amount"]}
        indexBy="period"
        margin={{ top: 40, right: 35, bottom: 65, left: 75 }}
        padding={0.3}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={({ index }) => colors[index % colors.length]}
        borderColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Period",
          legendPosition: "middle",
          legendOffset: 42,
          tickValues: 5,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Sales Amount",
          legendPosition: "middle",
          legendOffset: -70,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        label={(d) => `₹${new Intl.NumberFormat("en-IN").format(d.value)}`}
        labelFormat={(d) => `₹${new Intl.NumberFormat("en-IN").format(d)}`}
        role="application"
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={(e) =>
          `₹${new Intl.NumberFormat("en-IN").format(e.value)} in period: ${
            e.indexValue
          }`
        }
      />
    </Box>
  );
};

export default BarChart;
