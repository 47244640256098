import React, { useEffect, useState } from "react";
import "./BillHistory.css";
import DefaultImage from "../../Assets/DefaultImage.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {Box, Typography,IconButton} from "@mui/material";
import { useNavigate } from "react-router-dom";

const BillHistory = ({ updatedOrderItems }) => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter] = useState("all");
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  
  useEffect(() => {

    const fetchOrders = async () => {
      try {
        const response = await fetch("https://kamalicrackers.com/ijwyshs_kamali_backend/get_bill_history.php");
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log("Fetched Data: ", data);

        if (data.status === "success") {
          const sortedOrders = data.orders
            .map((order) => ({
              ...order,
              total_items: Array.isArray(order.items) ? order.items.length : 0,
            }))
            .sort((a, b) => b.id - a.id); // Sort orders in descending order based on order id
          setOrders(sortedOrders);
          if (sortedOrders.length > 0) {
            setSelectedOrder(sortedOrders[0]);
          }
        } else {
          console.error("Error fetching orders:", data.message);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, []);
  
  useEffect(() => {
    // Handle keyboard shortcuts
    const handleKeydown = (event) => {
      if (event.ctrlKey && event.key === "b") {
        event.preventDefault();
        console.log("Custom Ctrl+b action");

        if (event.ctrlKey && event.key === "b") {
          document.getElementById("history").click(); 
        }
      }
    };

    window.addEventListener("keydown", handleKeydown);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeydown);
    };
  }, []);

  useEffect(() => {
    if (updatedOrderItems && selectedOrder) {
      const updatedOrders = orders.map((order) => {
        if (order.id === selectedOrder.id) {
          return {
            ...order,
            items: updatedOrderItems,
          };
        }
        return order;
      });
      setOrders(updatedOrders);
    }
  }, [updatedOrderItems, selectedOrder, orders]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const calculateSubtotalAndTotal = (items) => {
    const subtotal = items.reduce(
      (acc, item) => acc + item.item_quantity * item.item_discounted_price,
      0
    );
    const totalDiscounts = items.reduce(
      (acc, item) =>
        acc +
        (item.item_original_price - item.item_discounted_price) *
          item.item_quantity,
      0
    );
    const total = subtotal - totalDiscounts;
    return { subtotal, total, totalDiscounts };
  };

  const filteredOrders = orders.filter((order) => {
    const matchesSearch =
      order.id.toString().includes(searchTerm) ||
      order.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesFilter = filter === "all" || order.status === filter;
    return matchesSearch && matchesFilter;
  });

  const closePopup = () => {
    setShowPopup(false);
  };

  const printBill = () => {
    if (!selectedOrder) return;

    const { id, name, items, sub_total, total_discounts, total_price, order_date, amount_received, balance, cashier_name, payment_mode } = selectedOrder;
    const formatDate = (date) => {
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const year = d.getFullYear();
      return `${day}-${month}-${year}`;
    };

    const date = formatDate(order_date);

    const convertToNumber = (value) => {
      return isNaN(value) ? 0 : Number(value);
    };

    let itemsHTML = items.map((item) => {
      const discountedPrice = convertToNumber(item.item_discounted_price);
      const quantity = convertToNumber(item.item_quantity);
      const totalPrice = (discountedPrice * quantity).toFixed(2);
      
      return `
        <tr>
          <td>${item.item_name}</td>
          <td>${quantity}</td>
          <td>${discountedPrice.toFixed(2)}</td>
          <td>${totalPrice}</td>
        </tr>
      `;
    }).join("");

    const billHTML = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>POS Thermal Print Bill</title>
        <style>
          body {
            font-family: 'Courier New', Courier, monospace;
            font-size: 10px;
            width: 300px;
            margin: 0 auto;
            padding: 0;
          }
          .header, .footer {
            text-align: center;
          }
          .content {
            margin-top: 10px;
            margin-bottom: 10px;
          }
          .content table {
            width: 100%;
            border-collapse: collapse;
          }
          .content th, .content td {
            text-align: left;
            padding: 2px 0;
          }
          .content th {
            border-bottom: 1px solid #000;
          }
          .content td {
            border-bottom: 1px dotted #000;
          }
          .content th, .content td {
            padding-left: 5px;
            padding-right: 5px;
          }
          .total {
            text-align: right;
          }
          .terms {
            text-align: center;
            margin-top: 10px;
          }
          .invoice-details {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
          }
          .invoice-details-left {
            text-align: left;
          }
          .invoice-details-right {
            text-align: right;
          }
        </style>
      </head>
      <body>
        <div class="header">
          <p><strong>Kamali Crackers</strong></p>
          <p>Ph.No.: 9005900858</p>
        </div>
        <div class="content">
          <div class="invoice-details">
            <div class="invoice-details-left">
              <p>Invoice No: ${id}</p>
              <p>Date: ${date}</p>
              <p>Customer: ${name}</p>
            </div>
            <div class="invoice-details-right">
              <p>Cashier: ${cashier_name}</p>
              <p>Payment Mode: ${payment_mode}</p>
            </div>
          </div>
          <table>
            <tr>
              <th>Item Name</th>
              <th>Qty</th>
              <th>Price</th>
              <th>Amount</th>
            </tr>
            ${itemsHTML}
          </table>
          <br><br>
          <table>
            <tr>
              <td>No Of items:</td>
              <td>${items.length}</td>
            </tr>
            <tr>
              <td>Total</td>
              <td class="total">${convertToNumber(sub_total).toFixed(2)}</td>
            </tr>
            <tr>
              <td>Discounts</td>
              <td class="total">${convertToNumber(total_discounts).toFixed(2)}</td>
            </tr>
            <tr>
              <td>Received</td>
              <td class="total">${convertToNumber(amount_received).toFixed(2)}</td>
            </tr>
            <tr>
              <td>Balance</td>
              <td class="total">${convertToNumber(balance).toFixed(2)}</td>
            </tr>
          </table>
        </div>
        <div class="footer">
          <p>Thanks for doing business with us!</p>
        </div>
      </body>
      </html>
    `;

    const printWindow = window.open("", "Print Bill", "width=400,height=600");
    printWindow.document.open();
    printWindow.document.write(billHTML);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <div className="billhistory">
      <Box display="flex" ml="1.5%" alignItems="center" justifyContent="space-between"
      sx={{
        // Responsive styles
        "@media (max-width: 768px)": {
          marginLeft: "-10%",
          marginBottom:"10px"
        },
        "@media (max-width: 480px)": {
          marginLeft: "-10%",
          marginBottom:"10px"
        },
      }}>
        <IconButton onClick={handleBackClick} id="history">
          <ArrowBackIcon />
        </IconButton>
        <Typography
          variant="h3"
          gutterBottom
          style={{ flexGrow: 1, textAlign: "center", marginLeft: "-48px"}}
        >
          Bill History
        </Typography>
      </Box>
      <div className="bill-order-sale-container">
        <div className="bill-sidebar">
          <input
            type="text"
            placeholder="Search Order"
            className="bill-search-order"
            value={searchTerm}
            onChange={handleSearch}
          />
          <div className="bill-filter-buttons">
            <h3>Order List Details</h3>
          </div>
          <div className="bill-order-list">
            {filteredOrders.map((order) => (
              <div
                key={order.id}
                className={`bill-order-item ${
                  selectedOrder && selectedOrder.id === order.id
                    ? "selected"
                    : ""
                }`}
                style={{
                  backgroundColor: selectedOrder && selectedOrder.id === order.id ? '#e0f7fa' : 'transparent',
                }}
                onClick={() => setSelectedOrder(order)}
              >
                <div className="bill-order-info-left">
                  <div className="bill-order-number">
                    Order #{order.id}{" "}
                  </div>
                  <div className="bill-order-customer">{order.name}</div>
                  <div className="bill-order-items">
                    {order.total_items} items
                  </div>
                </div>
                <div className="bill-order-info-right">
                  <div className="bill-order-date">
                    {formatDate(order.order_date)}
                  </div>
                  <div className="bill-order-total">
                    ₹ {calculateSubtotalAndTotal(order.items).subtotal}
                  </div>{" "}
                  {/* Display calculated subtotal */}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="bill-order-details">
          {selectedOrder && (
            <>
              <div className="bill-order-header">
                <div>
                  Order placed on {formatDate(selectedOrder.order_date)}
                </div>
                <div>Order ID: {selectedOrder.id}</div>
              </div>
              <div className="bill-summary-header">
                <span>Order #{selectedOrder.id}</span>
                <span>{selectedOrder.total_items} items</span>
              </div>
              <br />
              <div className="bill-order-info">
                <div className="bill-order-info-item">
                  <span className="bill-order-info-label">NAME</span>
                  <span className="bill-order-info-value">
                    {selectedOrder.name}
                  </span>
                </div>
                <div className="bill-order-info-item">
                  <span className="bill-order-info-label">ADDRESS</span>
                  <span className="bill-order-info-value">
                    {selectedOrder.address}
                  </span>
                </div>

                <div className="bill-order-info-item">
                  <span className="bill-order-info-label">CONTACT NO</span>
                  <span className="bill-order-info-value">
                    {selectedOrder.phone}
                  </span>
                </div>
              </div>
              <hr className="order-line" />
              <br />
              <div className="bill-order-summary-container">
                <div className="bill-order-summary">
                  <div className="bill-summary-header">
                    <span>Order Summary</span>
                    <span>{selectedOrder.total_items} items</span>
                  </div>
                  {Array.isArray(selectedOrder.items) &&
                  selectedOrder.items.length > 0 ? (
                    selectedOrder.items.map((item, index) => (
                      <div key={index} className="bill-summary-item">
                        <div className="bill-summary-item-image">
                          <img
                            src={`https://kamalicrackers.com/ijwyshs_kamali_backend/${item.item_image_url}`}
                            alt="Item"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = DefaultImage;
                            }}
                          />
                        </div>
                        <div className="bill-summary-item-description">
                          <div>{item.item_name}</div>
                          <div className="item_category">
                            {item.item_category}
                          </div>
                          <div>
                            {item.item_quantity} X{" "}
                            <b>₹{item.item_discounted_price}</b>
                          </div>
                        </div>
                        <div className="bill-summary-item-details">
                          <div>
                            ₹{item.item_quantity * item.item_discounted_price}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>No items found for this order.</div>
                  )}
                </div>
                <div className="bill-price-details">
                  <span className="bill-summary-header">Price Details</span>
                  <div className="bill-price-item">
                    <span>Sub Total:</span>
                    <span>
                      ₹{" "}
                      {calculateSubtotalAndTotal(selectedOrder.items).subtotal}
                    </span>
                  </div>
                  <div className="bill-price-item">
                    <span>Discounts:</span>
                    <span>
                      ₹{" "}
                      {
                        calculateSubtotalAndTotal(selectedOrder.items)
                          .totalDiscounts
                      }
                    </span>
                  </div>
                  <br />
                  <hr className="order-line" />
                  <br />
                  <div className="bill-price-item total">
                    <span>Total:</span>
                    <span>
                      ₹{" "}
                      {calculateSubtotalAndTotal(selectedOrder.items).subtotal}
                    </span>
                  </div>
                </div>
              </div>
              <div className="bill-order-actions">
                <button className="bill-convert-sale" onClick={printBill}>
                  Print Bill
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BillHistory;
