import React, { useState, useContext, useEffect, useRef } from "react";
import "./ShopPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as faRegularHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart } from "@fortawesome/free-solid-svg-icons"; // Import the solid heart icon
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { CartContext } from "./CartContext";
import { FavoriteContext } from "./FavoriteContext";
import { useLocation , useNavigate } from "react-router-dom";
import Categories from "./Categories";
import { Helmet } from "react-helmet";
import DefaultImage from "../../../Assets/DefaultImage.png";

const Shop = () => {
  const [sortOption, setSortOption] = useState("default");
  const { cart, addToCart, increaseQuantity, decreaseQuantity } =
    useContext(CartContext);
  const { favorites, addFavorite } = useContext(FavoriteContext); // Destructure addFavorite from context
  const [hoveredProductId, setHoveredProductId] = useState(null);
  const [productsData, setProductsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const categoriesRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
const initialCategory = location.state?.selectedCategory || null;

const [selectedCategory, setSelectedCategory] = useState(initialCategory);

  useEffect(() => {
    fetch("https://kamalicrackers.com/ijwyshs_kamali_backend/fetch_items.php")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Map the fetched date string to JavaScript Date objects for sorting
        const formattedData = data.map((item) => ({
          ...item,
          dateAdded: new Date(item.dateAdded),
        }));
        setProductsData(formattedData);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
        setError(error);
        setLoading(false);
      });
  }, []);

  const sortedProducts = [...productsData]
  .filter(
    (product) => !selectedCategory || product.category === selectedCategory
  )
  .sort((a, b) => {
    switch (sortOption) {
      case "latest":
        return new Date(b.dateAdded) - new Date(a.dateAdded);
      case "priceLowToHigh":
        return a.discounted_price - b.discounted_price;
      case "priceHighToLow":
        return b.discounted_price - a.discounted_price;
      default:
        return 0;
    }
  });

  const handleAddToCart = (product) => {
    addToCart(product);
  };

  const handleIncreaseQuantity = (productId) => {
    increaseQuantity(productId);
  };

  const handleDecreaseQuantity = (productId) => {
    decreaseQuantity(productId);
  };

  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  const handleImageClick = (product) => {
    navigate(`/product/${product.id}`, {
      state: {
        product: {
          ...product,
          image_url: product.image_url ? `https://kamalicrackers.com/ijwyshs_kamali_backend/${product.image_url}` : DefaultImage,
        },
      },
    });
  };

  const handleScroll = () => {
    const container = categoriesRef.current;
    setShowLeftArrow(container.scrollLeft > 0);
    setShowRightArrow(
      container.scrollLeft < container.scrollWidth - container.clientWidth
    );
  };

  const scrollLeft = () => {
    categoriesRef.current.scrollBy({ left: -200, behavior: "smooth" });
  };

  const scrollRight = () => {
    categoriesRef.current.scrollBy({ left: 200, behavior: "smooth" });
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const isFavorite = (productId) =>
    favorites.some((item) => item.id === productId);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="shop-container">
      <Helmet>
        <title>Kamali Crackers - Shop</title>
        <meta charset="UTF-8"></meta>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
        {/* Meta Description */}
        <meta
          name="description"
          content="Explore and shop premium quality crackers and fireworks online at Kamali Crackers. Browse categories, add favorites, and enjoy fast delivery across Tamil Nadu."
        />

        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="Kamali crackers, buy crackers online Sivakasi, online crackers Sivakasi, Sivakasi fireworks, Diwali crackers online Sivakasi, wholesale crackers Sivakasi, Sivakasi firecrackers, order crackers online Sivakasi, best crackers Sivakasi, cheap crackers Sivakasi, crackers delivery Sivakasi, Kamali crackers shop online, festival fireworks Sivakasi, Kamali crackers wholesale, crackers online Tamil Nadu, fireworks online Sivakasi, Kamali crackers for Diwali, bulk crackers Sivakasi, discount crackers Sivakasi, crackers wholesale Sivakasi, Sivakasi fireworks for festivals, buy fireworks online Sivakasi, Kamali crackers sale, Sivakasi gift box crackers, Sivakasi fancy crackers"
        />

        {/* Author */}
        <meta name="Sankarapandi" content="Kamali Crackers" />

        {/* Open Graph Meta Tags for Social Media */}
        <meta property="og:title" content="Shop - Kamali Crackers" />
        <meta
          property="og:description"
          content="Explore and shop premium quality crackers and fireworks online at Kamali Crackers. Browse categories, add favorites, and enjoy fast delivery across Tamil Nadu."
        />
        <meta property="og:image" content="/brand-image.jpg" />
        <meta
          property="og:url"
          content="https://www.kamalicrackers.com/shop"
        />
        <meta property="og:type" content="website" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Shop - Kamali Crackers" />
        <meta
          name="twitter:description"
          content="Explore and shop premium quality crackers and fireworks online at Kamali Crackers. Browse categories, add favorites, and enjoy fast delivery across Tamil Nadu."
        />
        <meta name="twitter:image" content="/brand-image.jpg" />
      </Helmet>

      <div className="sort-options">
        <label htmlFor="sort">Sortby:</label>
        <select id="sort" value={sortOption} onChange={handleSortChange}>
          <option value="default">Default</option>
          <option value="latest">Latest</option>
          <option value="priceLowToHigh">Price: Low to High</option>
          <option value="priceHighToLow">Price: High to Low</option>
        </select>
      </div>
      <div className="categories-container">
        {showLeftArrow && (
          <button className="scroll-button left" onClick={scrollLeft}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
        )}
        <div
          className="categories-wrapper"
          ref={categoriesRef}
          onScroll={handleScroll}
        >
          <Categories onCategoryClick={handleCategoryClick} />
        </div>
        {showRightArrow && (
          <button className="scroll-button right" onClick={scrollRight}>
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
        )}
      </div>
      <div className="pcd-product-grid">
        {sortedProducts.map((product) => (
          <div
            key={product.id}
            className="pcd-product-card"
            onMouseEnter={() => setHoveredProductId(product.id)}
            onMouseLeave={() => setHoveredProductId(null)}
          >
            <div className="discount-badge">{product.discount}%</div>
            {product.image_url ? (
              <img
                src={`https://kamalicrackers.com/ijwyshs_kamali_backend/${product.image_url}`}
                alt={product.name}
                onClick={() => handleImageClick(product)}
              />
            ) : (
              <img
                src={DefaultImage}
                alt={product.name}
                onClick={() => handleImageClick(product)}
              />
            )}
            <div className="product-info">
              <h2>{product.name}</h2>
              <div className="tooltip">{product.name}</div>
              <p>{product.category}</p>
              <p>
                <span className="original-price">
                  ₹{product.original_price}
                </span>
                <span className="discounted-price">
                  {" "}
                  ₹{product.discounted_price}
                </span>
              </p>
              {cart.find((item) => item.id === product.id) ? (
                <div className="quantity-controls">
                  <button
                    className="quantity-button"
                    onClick={() => handleDecreaseQuantity(product.id)}
                  >
                    -
                  </button>
                  <span className="quantity">
                    {cart.find((item) => item.id === product.id).quantity}
                  </span>
                  <button
                    className="quantity-button"
                    onClick={() => handleIncreaseQuantity(product.id)}
                  >
                    +
                  </button>
                </div>
              ) : (
                <button
                  className="add-to-cart-button"
                  onClick={() => handleAddToCart(product)}
                >
                  Add
                </button>
              )}
            </div>
            <div className="heart-icon" onClick={() => addFavorite(product)}>
              <FontAwesomeIcon
                icon={isFavorite(product.id) ? faHeart : faRegularHeart} // Use the solid heart if the product is a favorite
                style={{
                  color: isFavorite(product.id) ? "red" : "black",
                  fontSize: 18,
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Shop;
