import React, { useEffect, useState } from "react";
import "./ActPopUp.css";

const ActPopUp = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const visitCount = Number(localStorage.getItem('visitCount')) || 0;

    if (visitCount === 0) {
      setShow(true);
    }
  }, []);

  const handleClose = () => {
    const visitCount = Number(localStorage.getItem('visitCount')) || 0;
    localStorage.setItem('visitCount', visitCount + 1);

    setShow(false);
  };

  return (
    show && (
      <div className="popup-box">
        <p>
          As per 2018 supreme court order, online sale of firecrackers is not
          permitted! We value our customers and at the same time, respect
          jurisdiction. We request you to add your products to the cart and
          submit the required crackers through the enquiry button. We will
          contact you within 24 hrs and confirm the order through WhatsApp or
          phone call. Please add and submit your enquiries.
        </p>
        <button className="ok-button" onClick={handleClose}>
          OK
        </button>
      </div>
    )
  );
};

export default ActPopUp;
