import React from 'react';
import './Home.css';
import AddPartiesImage from '../../Assets/AddParties.jpg';

const Home = ({ setActivePage }) => {
  const handleButtonClick = () => {
    setActivePage('add'); // Set active page to 'add'
  };

  return (
    <div className="home-container">
      <div className="welcome">
        <img src={AddPartiesImage} alt="Add Parties" className="add-parties-image" />
        <div className="add-party">
          <p>Add your customers & suppliers. Manage your business with them.</p>
          <button onClick={handleButtonClick}>Add Your First Party</button>
        </div>
      </div>
    </div>
  );
};

export default Home;
