import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import "./Item.css";

const FloatingPlaceholderInput = ({
  label,
  value,
  onChange,
  type = "text",
  textarea = false,
  hasRupeeSymbol = false,
  pattern = null,
  errorMessage = "", // New prop for error message
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const theme = useTheme();

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => {
    if (value === "") setIsFocused(false);
  };

  return (
    <Box className={`input-container ${hasRupeeSymbol ? "half-width" : ""}`}>
      {textarea ? (
        <textarea
          value={value}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={onChange}
          required
        />
      ) : (
        <Box
          className={`input-with-symbol ${
            isFocused && hasRupeeSymbol ? "rupee-symbol-active" : ""
          }`}
        >
          {hasRupeeSymbol && <span className="rupee-symbol">₹</span>}
          <input
            type={type}
            value={value}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={onChange}
            className={hasRupeeSymbol ? "with-rupee" : ""}
            style={{ borderColor: theme.palette.primary.main }}
            required
            pattern={pattern}
          />
          <label
            className={`input-placeholder ${isFocused || value ? "active" : ""}`}
          >
            {label}
          </label>
        </Box>
      )}
      {errorMessage && (
        <Box className="error-message" sx={{ marginTop: "8px" }}>
          {errorMessage}
        </Box>
      )}
    </Box>
  );
};

export default FloatingPlaceholderInput;
