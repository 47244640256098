import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './ProductDetail.css';
import { CartContext } from './CartContext';
import { FavoriteContext } from './FavoriteContext';
import CategoriesGrid from '../Home/CategoriesGrid';
import DefaultImage from "../../../Assets/DefaultImage.png";

const ProductDetail = () => {
  const location = useLocation();
  const { product } = location.state || {};
  const { cart, addToCart, increaseQuantity, decreaseQuantity } = useContext(CartContext);
  const { favorites, addFavorite } = useContext(FavoriteContext);

  const cartItem = cart.find(item => item.id === product?.id);
  const initialQuantity = cartItem ? cartItem.quantity : 0;
  const [quantity, setQuantity] = useState(initialQuantity);

  const isFavorite = favorites.some(item => item.id === product?.id);

  useEffect(() => {
    if (cartItem) {
      setQuantity(cartItem.quantity);
    }
  }, [cartItem]);

  if (!product) {
    return <div>Product not found</div>;
  }

  const handleIncreaseQuantity = () => {
    if (cartItem) {
      increaseQuantity(product.id);
    } else {
      addToCart({ ...product, quantity: 1 });
    }
    setQuantity(prevQuantity => prevQuantity + 1);
  };

  const handleDecreaseQuantity = () => {
    if (cartItem && quantity > 1) {
      decreaseQuantity(product.id);
      setQuantity(prevQuantity => prevQuantity - 1);
    } else if (quantity === 1) {
      setQuantity(0);
    }
  };

  const handleAddToCart = () => {
    if (quantity === 0) {
      addToCart({ ...product, quantity: 1 });
      setQuantity(1);
    }
  };

  const handleAddToWishlist = () => {
    addFavorite(product);
  };

  return (
    <div className='PD-body'>
      <div className="new-product-detail-container">
        <div className="new-product-detail">
          <div className="new-product-image">
            <img src={product.image_url || DefaultImage} alt={product.name} />
            <div className="new-discount-badge">{product.discount}%</div>
          </div>
          <div className="new-product-info">
            <div className="new-breadcrumb">
              <a href="/">Home</a> / <span>{product.category || 'Uncategorized'}</span> / <span>{product.name}</span>
            </div>
            <h1>{product.name}</h1>
            <p className="new-category">Category: {product.category || 'Uncategorized'}</p>

            <p className="new-price">
              <span className="new-original-price">₹{product.original_price}</span>
              <span className="new-discounted-price"> ₹{product.discounted_price}</span>
            </p>
            <div className="new-cart-controls">
              {quantity === 0 ? (
                <button className="new-add-to-cart-button" onClick={handleAddToCart}>Add to Cart</button>
              ) : (
                <div className="new-quantity-controls">
                  <button className="new-quantity-button" onClick={handleDecreaseQuantity}>-</button>
                  <span className="new-quantity">{quantity}</span>
                  <button className="new-quantity-button" onClick={handleIncreaseQuantity}>+</button>
                </div>
              )}
            </div>
            <div className="new-button-group">
              <button className="new-wishlist-button" onClick={handleAddToWishlist}>
                {isFavorite ? 'Remove from Wishlist' : 'Add to Wishlist'}
              </button>
            </div>
          </div>
        </div>
        <hr className='last-Line'></hr>
        <h2 className='Related-Products'>Other Categories</h2>
        
      </div>
      <CategoriesGrid />
    </div>
  );
};

export default ProductDetail;
