import React, { useState, useEffect } from 'react';
import './ShopPage.css';

const Categories = ({ onCategoryClick }) => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = () => {
    fetch('https://kamalicrackers.com/ijwyshs_kamali_backend/fetch_categories.php')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setCategories(data); // Assuming data is an array of objects {id, name}
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
      });
  };

  const handleCategoryClick = (category) => {
    onCategoryClick(category === 'All' ? null : category);
  };

  return (
    <div className="categories-list">
      <span 
        className="category-item"
        onClick={() => handleCategoryClick('All')}
      >
        All
      </span>
      {categories.map(category => (
        <span 
          key={category.id} 
          className="category-item" 
          onClick={() => handleCategoryClick(category.name)}
        >
          {category.name}
        </span>
      ))}
    </div>
  );
};

export default Categories;
